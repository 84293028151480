const ModuleGlobal = {
  state: {
    Loader: false,
    LoaderSaleMan: false
  },
  mutations: {
    closeLoader (state) {
      state.Loader = false
    },
    openLoader (state) {
      state.Loader = true
    },
    closeLoaderSaleMan (state) {
      state.LoaderSaleMan = false
    },
    openLoaderSaleMan (state) {
      state.LoaderSaleMan = true
    }
  },
  actions: {
  }
}
export default ModuleGlobal
