import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosGetNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetNewsBanner (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news_banner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosManageNewsBanner (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/create_and_edit_news_banner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSwitchNewsStatus (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/news_update_status`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/create_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/edit_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/detail_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailNewsProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news_relate_product_by_product_id`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/delete_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetNewsForEorC (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/list_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetNewsBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateGroupNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/create`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditGroupNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/edit`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetNewsProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news_by_product_id`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetGroupsNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/list_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSetIndexGroupsNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/set_index`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteGroupsNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/delete`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetDetailGroupsNews (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_group/detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosRelationNewsProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/get_news_relate_product_by_product_id`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
