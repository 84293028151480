import AxiosAcceptPDPA from './axios_PDPA'
const NSGModuleAcceptPDPA = {
  state: {
    stateAcceptPDPA: []
  },
  mutations: {
    mutationAcceptPDPA (state, data) {
      state.stateAcceptPDPA = data
    }
  },
  actions: {
    async actionAcceptPDPA (context, access) {
      const response = await AxiosAcceptPDPA.axiosAcceptPDPA(access)
      await context.commit('mutationAcceptPDPA', response)
    }
  }
}

export default NSGModuleAcceptPDPA
