import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosCheckAcceptProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}accecpt/check_accecpt_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUpdateAcceptProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}accecpt/update_accecpt_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateCommentReview (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}review/create_comment_review`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCheckListReview (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}review/check_list_review`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListAllRatingOrder (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/list_all_rating_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailCommentBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/list_product_order_rating_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
