import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosListAllReviewForSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}review/get_all_product_review_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailReviewForSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}review/detail_product_review_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUpdateStatusComment (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}review/update_status_comment`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SetPoints (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/set_points`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SetPointStatus (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/set_point_status`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SetCommentStatus (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/show_hide`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SetSellerList (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/seller_list`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SellerDetail (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/seller_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async LogList (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/log_list`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Seller_manage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/seller_manage`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
