import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}
// /api/flashsale/list_flashsale_seller
export default {
  async ListFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/list_flashsale_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/detail_flashsale_seller_edit`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailForCreateFlashSale (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/show_detail_create_flashsale`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/create_flashsale`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeleteFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/delete_flashsale_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/update_status_flashsale_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/edit_flashsale`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetForSelectFlashSaleSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}flashsale/list_product_price_by_cate`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ExportFlashSale (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}export_flash_sale_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
