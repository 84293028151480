import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'authNSG')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    // console.log('ONEDATA NAAAa', oneData)
    if (oneData.user === undefined) {
      localStorage.removeItem('authNSG')
    } else {
      const auth = {
        headers: { Authorization: `Bearer ${oneData.user.access_token}` }
      }
      return auth
    }
  }
}

export default {
  // Shop Userdetail Page
  async GetUPSUserDetailPage () {
    const auth = await GetToken()
    // console.log('GetUserDetailPage', data)
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}user_detail`, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Bind Account and Auto Add OneChat Bot + Create Shop
  async SetUPSBindAccount (data) {
    const auth = await GetToken()
    // console.log('SetBindAccount', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/bind_account_with_one_id`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeUPSPasswordMarket (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/change_password_market`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RedirectResetPasswordAuth (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/reset_password_market`, data)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ResetPassword (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_new_password`, data)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreatedAddressTaxinvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}taxInvoices/insert`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAddressTaxinvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}taxInvoices`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAddressTaxinvoicePurchase () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/get_corporate_address`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Detail Business ID
  async axiosUPSDetailBusiness (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_business`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  //
  async axiosUPSUpdateUserProfile (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/edit_user`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUPSListUserAddress () {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/list_user_address_ups`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUPSUpdateUserAddress (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/update_user_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUPSListUserAddressTex () {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/list_invoice_address_ups`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Forget Password of market
  async axiosUPSForgetPassword (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/send_forget_password_market_link`, val)
      // console.log(response)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosAddUserAddress (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/add_user_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteUserAddress (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/delete_user_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUpdateUserEmailAndPhone (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/update_email_and_phone`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteInvoiceAddress (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/delete`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
