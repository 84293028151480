<template>
  <v-app>
    <ModalPDPA ref="ModalPDPA"/>
    <keep-alive :max="10">
      <router-view />
    </keep-alive>
    <Loader/>
    <LoaderSaleMan/>
  </v-app>
</template>

<script>
import '@/styles/main.scss'
import Loader from '@/components/sharedComponent/Loader'
import LoaderSaleMan from '@/components/sharedComponent/LoaderSaleMan'
import ModalPDPA from '@/components/NSG/Modal/PDPA'
export default {
  components: {
    Loader,
    LoaderSaleMan,
    ModalPDPA
  },
  metaInfo () {
    return {
      title: 'บริษัท วันรัต (หน่ำเซียน) จำกัด',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'th-TH'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:title', content: 'บริษัท วันรัต (หน่ำเซียน) จำกัด', template: 'บริษัท วันรัต (หน่ำเซียน) จำกัด', vmid: 'og:title' }
      ]
    }
  },
  // async beforeCreate () {
  //   var part = window.location.pathname.split('/')
  //   if (part[2] === 'DetailProductNSG') {
  //     const url = window.location.href
  //     var explodedURL = url.split('/')
  //     console.log('Tong', explodedURL)
  //     var lastElement = explodedURL[explodedURL.length - 1]
  //     var array = lastElement.split('-')
  //     var product_id = array[array.length - 1]
  //     console.log('Tong Product_id', product_id)
  //     var urlweb = `${process.env.VUE_APP_IMAGE_DOMAIN}/NSG/DetailProductNSG/` + lastElement
  //     console.log('Tong urlweb', urlweb)
  //     await this.axios({
  //       url: `${process.env.VUE_APP_BACK_END}meta_tag_product`,
  //       data: { product_id: product_id },
  //       headers: '',
  //       method: 'POST'
  //     }).then((response) => {
  //       var data_img = response.data.data.image_path
  //       var data_name = response.data.data.name
  //       var shot_dec = response.data.data.short_desc === null ? '' : response.data.data.short_desc
  //       const head = document.head
  //       var metaTagURL = document.createElement('meta')
  //       metaTagURL.setAttribute('property', 'og:url')
  //       metaTagURL.setAttribute('name', 'url')
  //       metaTagURL.setAttribute('content', url)
  //       head.appendChild(metaTagURL)

  //       var metaTagType = document.createElement('meta')
  //       metaTagType.setAttribute('property', 'og:type')
  //       metaTagType.setAttribute('content', 'website')
  //       head.appendChild(metaTagType)

  //       var metaTagTitle = document.createElement('meta')
  //       metaTagTitle.setAttribute('property', 'og:title')
  //       metaTagTitle.setAttribute('name', 'title')
  //       metaTagTitle.setAttribute('content', data_name)
  //       // document.head.appendChild(metaTagTitle)
  //       head.appendChild(metaTagTitle)

  //       var metaTagDescription = document.createElement('meta')
  //       metaTagDescription.setAttribute('property', 'og:description')
  //       metaTagDescription.setAttribute('name', 'description')
  //       metaTagDescription.setAttribute('content', shot_dec)
  //       head.appendChild(metaTagDescription)
  //       if (data_img !== undefined) {
  //         var metaTagImage = document.createElement('meta')
  //         metaTagImage.setAttribute('property', 'og:image')
  //         metaTagImage.setAttribute('name', 'image')
  //         metaTagImage.setAttribute('content', data_img)
  //         head.appendChild(metaTagImage)

  //         var metaTagImageURL = document.createElement('meta')
  //         metaTagImageURL.setAttribute('property', 'og:image:url')
  //         metaTagImageURL.setAttribute('name', 'image:url')
  //         metaTagImageURL.setAttribute('content', data_img)
  //         head.appendChild(metaTagImageURL)

  //         var metaTagSecureURL = document.createElement('meta')
  //         metaTagSecureURL.setAttribute('property', 'og:image:secure_url')
  //         metaTagSecureURL.setAttribute('name', 'image:secure_url')
  //         metaTagSecureURL.setAttribute('content', data_img)
  //         head.appendChild(metaTagSecureURL)
  //       }
  //     }).catch((error) => {
  //       console.log(error)
  //     })
  //   }
  // },
  async created () {
    this.$EventBus.$emit('getUserDetail')
    this.$EventBus.$on('checkPDPA', this.checkPDPA)
  },
  methods: {
    checkPDPA () {
      console.log('Open Dialog PDPA')
      this.$refs.ModalPDPA.open()
    }
  }
}
</script>

<style>
@import './assets/styles.css';
@import './assets/content-styles.css';
</style>
