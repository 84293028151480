import AxiosCategory from './axios_category'

const ModuleManageCategory = {
  state: {
    stateGetCategory: [],
    // Main Category Buyer
    stateMainCategory: [],
    // Main + Sub Category Buyer
    stateMainAndSubCategory: []
  },
  getters: {
    getterMainAndSubCategory: (state) => {
      var datas = []
      datas = state.stateMainCategory
      datas.data[datas.length + 1] = {
        category_gp: null,
        category_logo_path: null,
        category_name: 'สินค้าทั้งหมด',
        created_at: '2023-06-14T06:41:05.000000Z',
        hidden_category: [],
        hidden_type: '0',
        hierachy: null,
        id: 0,
        index_category: null,
        seller_shop_id: 1,
        sub_category: [],
        unique_custom_category: null,
        updated_at: '2023-06-14T06:41:05.000000Z'
      }
      return datas
    }
  },
  mutations: {
    mutationGetCategory (state, data) {
      state.stateGetCategory = data
    },
    // Main Category Buyer
    mutationsMainCategory (state, data) {
      state.stateMainCategory = data
    },
    // Main + Sub Category Buyer
    mutationsMainAndSubCategory (state, data) {
      state.stateMainAndSubCategory = data
    }
  },
  actions: {
    async actionGetCategory (context, access) {
      const response = await AxiosCategory.axiosGetCategory(access)
      await context.commit('mutationGetCategory', response)
    },
    // Main Category Buyer
    async actionsMainCategory (context, access) {
      const response = await AxiosCategory.GetMainCategory(access)
      await context.commit('mutationsMainCategory', response)
    },
    // Main + Sub Category Buyer
    async actionsMainAndSubCategory (context, access) {
      const response = await AxiosCategory.GetMainAndSubCategory(access)
      await context.commit('mutationsMainAndSubCategory', response)
    }
  }
}

export default ModuleManageCategory
