import axios from 'axios'
import { Decode } from '@/services'
// import { filter } from 'jszip'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosPostCreate (val) {
    for (const v of val.values()) {
      console.log('Formdata', v)
    }
    const auth = await GetToken()
    console.log(auth)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}pop_up/create`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetPopup () {
    const data = {
      seller_shop_id: 1
    }
    const auth = await GetToken()
    // console.log(auth)
    // console.log('axiosPostCreate-->', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}pop_up`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetBanner (data) {
    const auth = await GetToken()
    // console.log(auth)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}banner/getBannerDetail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosAddBanner (val) {
    const auth = await GetToken()
    // console.log(auth)
    // console.log('ก่อนยิ่ง API', val)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}banner/addBanner`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getReviewProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_all_review_in_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getMemberClass () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_member_class`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async editMemberClass (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/edit_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Order Seller
  async OrderForSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/list_order_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailOrderForSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/detail_order_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RelatedProductSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}related_product/list_related_product_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ImportExcel (data) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = await GetToken()
    const data2 = {
      seller_shop_id: data.seller_shop_id,
      token: oneData.user.access_token,
      product_data: data.product_data,
      product_with_wholesale: data.product_with_wholesale,
      lock_price_of_product: data.lock_price_of_product
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/UploadExcel`, data2, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListUserForSeller () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}order/list_user_for_seller`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/cancel_order_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ConfirmOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/active_order_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListRelated (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}related_product/list_related_product_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListHistotyUpdate (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}list_history_update_Stock`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailHistotyUpdate (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}detail_history_update_price`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateRelated (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}related_product/update_related_product_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RandomRelated (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}related_product/random_related_product_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async excelExport (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}exports/excel/history_update_stock`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async filterRelated (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/list/related_product/seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RemoveRelated (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}related_product/delete_related_product_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async TemplateProduct (val) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = await GetToken()
    const data2 = {
      token: oneData.user.access_token
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}exports/excel/template_product`, data2, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ExportDataInSku () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}export_data_in_sku`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailWMSFail (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}pos/detail_wms_fail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SendWMSFail (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}pos/send_wms_fail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditInvoice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/edit_invoice`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      // console.log(error.response.data)
      return error.response.data
    }
  },
  async SettingEditInvoice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/setting_edit_invoice`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AddSearchBanner (val) {
    const auth = await GetToken()
    console.log(auth)
    console.log('ก่อนยิ่ง API', val)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}banner/addSearchBanner`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SentChat (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}chat_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardNewProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}list_new_product_dashboard`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardAllProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}list_product_dashboard`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardExportNew (val) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}new_arrival_product`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardExportAll (val) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}export_dashboard_product`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListCancelOrderSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/list_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailCancelOrderSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/detail_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusCancelOrderSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/update_status_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeStatusCancelOrderSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/change_status_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListAllSaleMan (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/list_all_sale_man`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SentEtax (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}sent_etax`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
