import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async getBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PurchaseAmount (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/purchaseAmount`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async HowManyOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/howManyOrder`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getRevenue (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/revenue`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getSale (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/sale`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Product (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/top10Product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Value (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/Top10Value`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Buyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/top10Buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getOrderList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/orderList`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getExport (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/dashboardExportExcel/start_date/end_date/shop_id`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CampaignPurchase (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_purchase`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CampaignMemberClass (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListMemberClassDashboard (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CampaignTop10Product (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardCampaignChart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_chart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardCampaignTable (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_table`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardCampaignTotal (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_campaign_total`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async TransportMap (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/map/transport`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async TransportData (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/top10transport`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async TransportExport (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/export/top10transport`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardNewMenber (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/dashboard_new_member`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardOrderComment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/order/feedback`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardComment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/feedback`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardVoucherHead (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardVoucherList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/voucherList`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashboardVoucherPurchase (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/voucherPurchase`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Dashboardtop10BestSellerVoucher (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/top10BestSellerVoucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Dashboardtop10MostUseVoucher (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/top10MostUseVoucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
