<template lang="html">
  <div>
    <v-overlay :value="LoaderSaleMan" style="z-index: 99999999;">
      <v-progress-circular indeterminate size="64" color="#2A70C3"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    LoaderSaleMan () {
      return this.$store.state.ModuleGlobal.LoaderSaleMan
    }
  }
}
</script>

<style lang="css" scoped>
</style>
