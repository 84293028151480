import AxiosManageLandingPage from './axios_manage_landing_page'

const ModuleManageLandingPage = {
  state: {
    stateCreateLadingPage: [],
    stateListLadingPage: [],
    stateEdiStatusLadingPage: [],
    stateEditLadingPage: [],
    stateDetailLadingPageSeller: [],
    stateDetailLadingPage: [],
    stateDeleteLadingPage: [],
    stateProductLadingPage: []
  },
  mutations: {
    mutationCreateLadingPage (state, data) {
      state.stateCreateLadingPage = data
    },
    mutationListLadingPage (state, data) {
      state.stateListLadingPage = data
    },
    mutationEdiStatusLadingPage (state, data) {
      state.stateEdiStatusLadingPage = data
    },
    mutationEditLadingPage (state, data) {
      state.stateEditLadingPage = data
    },
    mutationDetailLadingPageSeller (state, data) {
      state.stateDetailLadingPageSeller = data
    },
    mutationDetailLadingPage (state, data) {
      state.stateDetailLadingPage = data
    },
    mutationDeleteLadingPage (state, data) {
      state.stateDeleteLadingPage = data
    },
    mutationProductLadingPage (state, data) {
      state.stateProductLadingPage = data
    }
  },
  actions: {
    async actionCreateLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosCreateLadingPage(access)
      await context.commit('mutationCreateLadingPage', response)
    },
    async actionListLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosListLadingPage(access)
      await context.commit('mutationListLadingPage', response)
    },
    async actionEdiStatusLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosEdiStatusLadingPage(access)
      await context.commit('mutationEdiStatusLadingPage', response)
    },
    async actionEditLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosEditLadingPage(access)
      await context.commit('mutationEditLadingPage', response)
    },
    async actionDetailLadingPageSeller (context, access) {
      const response = await AxiosManageLandingPage.axiosDetailLadingPageSeller(access)
      await context.commit('mutationDetailLadingPageSeller', response)
    },
    async actionDetailLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosDetailLadingPage(access)
      await context.commit('mutationDetailLadingPage', response)
    },
    async actionDeleteLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosDeleteLadingPage(access)
      await context.commit('mutationDeleteLadingPage', response)
    },
    async actionProductLadingPage (context, access) {
      const response = await AxiosManageLandingPage.axiosProductLadingPage(access)
      await context.commit('mutationProductLadingPage', response)
    }
  }
}

export default ModuleManageLandingPage
