import AxiosCampaign from './axios_campaign'

const ModuleCampaign = {
  state: {
    stateListCampaign: [],
    stateCreateCampaign: [],
    stateEditCampaign: [],
    stateDetailCampaign: [],
    stateDeleteCampaign: [],
    stateListCampaignForClass: [],
    stateListProductCampaing: [],
    stateShowStatusPromotion: []
  },
  mutations: {
    mutationListCampaign (state, data) {
      state.stateListCampaign = data
    },
    mutationCreateCampaign (state, data) {
      state.stateCreateCampaign = data
    },
    mutationEditCampaign (state, data) {
      state.stateEditCampaign = data
    },
    mutationDetailCampaign (state, data) {
      state.stateDetailCampaign = data
    },
    mutationDeleteCampaign (state, data) {
      state.stateDeleteCampaign = data
    },
    mutationListCampaignForClass (state, data) {
      state.stateListCampaignForClass = data
    },
    mutationListProductCampaing (state, data) {
      state.stateListProductCampaing = data
    },
    mutationShowStatusPromotion (state, data) {
      state.stateShowStatusPromotion = data
    }
  },
  actions: {
    async actionListCampaign (context, access) {
      const response = await AxiosCampaign.axiosListCampaign(access)
      await context.commit('mutationListCampaign', response)
    },
    async actionCreateCampaign (context, access) {
      const response = await AxiosCampaign.axiosCreateCampaign(access)
      await context.commit('mutationCreateCampaign', response)
    },
    async actionEditCampaign (context, access) {
      const response = await AxiosCampaign.axiosEditCampaign(access)
      await context.commit('mutationEditCampaign', response)
    },
    async actionDetailCampaign (context, access) {
      const response = await AxiosCampaign.axiosDetailCampaign(access)
      await context.commit('mutationDetailCampaign', response)
    },
    async actionDeleteCampaignList (context, access) {
      const response = await AxiosCampaign.axiosDeleteCampaign(access)
      await context.commit('mutationDeleteCampaign', response)
    },
    async actionListCampaignForClass (context, access) {
      const response = await AxiosCampaign.axiosListCampaignForClass(access)
      await context.commit('mutationListCampaignForClass', response)
    },
    async actionListProductCampaing (context, access) {
      const response = await AxiosCampaign.axiosListProductCampaing(access)
      await context.commit('mutationListProductCampaing', response)
    },
    async actionShowStatusPromotion (context, access) {
      const response = await AxiosCampaign.axiosShowStatusPromotion(access)
      await context.commit('mutationShowStatusPromotion', response)
    }
  }
}

export default ModuleCampaign
