import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async getListMemberClass () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_member_class`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/detail_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/edit_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/create_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/delete_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
