const itemRules = {
  state: {
    month: [
      v => (v && v <= 12) || 'จำนวนเดือนไม่มากกว่า 12',
      v => (v && v >= 1) || 'จำนวนเดือนต้องมากกว่า 0'
    ],
    // promotion
    name: [v => !!v || 'กรุณาระบุชื่อ'],
    code: [
      v => !!v || 'กรุณาระบุโค้ดส่วนลดเป็นตัวพิมพ์เล็ก',
      v => v.length >= 4 || 'กรุณาระบุโค้ดส่วนลดตั้งแต่ 4-15 ตัวขึ้นไป',
      v => v.length <= 15 || 'กรุณาระบุโค้ดส่วนลดตั้งแต่ 4-15 ตัวขึ้นไป'
    ],
    voucherCode: [
      v => !!v || 'กรุณาระบุรหัสเวาเชอร์',
      v => v.length >= 4 || 'รหัสเวาเชอร์ 4-24 ตัว'
    ],
    time: [v => !!v || 'กรุณาระบุวันเวลา'],
    onlyTime: [v => !!v || 'กรุณาระบุเวลา'],
    amount: [
      v => !!v || 'กรุณาระบุจำนวนโปรโมชัน',
      v => parseInt(v) > 0 || 'กรุณาระบุจำนวนโปรโมชันมากกว่า 0'
    ],
    amountVoucher: [
      v => !!v || 'กรุณาระบุจำนวนเวาเชอร์',
      v => parseInt(v) > 0 || 'กรุณาระบุจำนวนเวาเชอร์มากกว่า 0',
      v => parseInt(v) <= 100000 || 'กรุณาระบุจำนวนเวาเชอร์น้อยกว่า 100,000'
    ],
    amountVoucherWhenEdit (before, after) {
      if (parseInt(after) >= 100000) {
        return ['กรุณาระบุจำนวนเวาเชอร์น้อยกว่า 10,000']
      } else if (after.length <= 0 || isNaN(parseInt(after))) {
        return ['กรุณาระบุจำนวนเวาเชอร์']
      } else if (parseInt(after) <= 0) {
        return ['กรุณาระบุจำนวนเวาเชอร์มากกว่า 0']
      } else {
        if (parseInt(before) > parseInt(after)) {
          return ['จำนวนเวาเชอร์ห้ามน้อยกว่าเดิม']
        }
      }
    },
    amountPoint: [
      v => !!v || 'กรุณาระบุจำนวนเท่า',
      v => parseInt(v) > 0 || 'กรุณาระบุจำนวนเท่ามากกว่า 0'
    ],
    min: [
      v => !!v || 'กรุณาระบุจำนวนขั้นต่ำ',
      v => parseInt(v) > 0 || 'ต้องกรอกส่วนลดมากกว่า 0 บาท'
    ],
    discount: [
      v => !!v || 'กรุณาระบุจำนวนส่วนลด',
      v => parseInt(v) > 0 || 'ต้องกรอกส่วนลดมากกว่า 0 บาท'
    ],
    priveVoucher: [
      v => !!v || 'กรุณาระบุราคา',
      v => parseInt(v) > 0 || 'ต้องกรอกราคามากกว่า 0 บาท',
      v => parseInt(v) <= 1000000 || 'ต้องกรอกราคาน้อยกว่า 1,000,000 บาท'
    ],
    discountPercent: [
      v => !isNaN(parseInt(v)) || 'กรุณาระบุจำนวนส่วนลด',
      v => parseInt(v) > 0 || 'ต้องกรอกส่วนลดมากกว่า 0%',
      v => parseInt(v) < 100 || 'ต้องกรอกส่วนลดไม่เกิน 99%'
    ],
    discountPercentShipping: [
      v => !isNaN(parseInt(v)) || 'กรุณาระบุจำนวนส่วนลด',
      v => parseInt(v) > 0 || 'ต้องกรอกส่วนลดมากกว่า 0%',
      v => parseInt(v) <= 100 || 'ต้องกรอกส่วนลดห้ามเกิน 100%'
    ],
    discountPercentShippingLevel1: [
      v => !isNaN(parseInt(v)) || 'กรุณาระบุจำนวนส่วนลด',
      v => parseInt(v) <= 100 || 'ต้องกรอกส่วนลดห้ามเกิน 100%'
      // v => parseInt(v) > 0 || 'ต้องกรอกส่วนลดมากกว่า 0%'
    ],
    price: [v => !!v || 'กรุณาระบุราคา'],
    MessageStatus: [v => !!v || 'กรุณาเลือกสถานะสินค้า'],
    Unit: [v => !!v || 'กรุณาเลือกหน่วยการขาย'],
    // product
    moreThanZero4: [
      v => !!v || 'กรุณากรอกข้อมูล',
      v => (v && v >= 1) || 'จำนวนต้องมากกว่า 0',
      v => (v && v <= 9999.99) || 'จำนวนต้องไม่เกิน 4 หลัก'
    ],
    moreThanZero5: [
      v => !!v || 'กรุณากรอกข้อมูล',
      v => (v && v >= 1) || 'จำนวนต้องมากกว่า 0',
      v => (v && v <= 99999.99) || 'จำนวนต้องไม่เกิน 5 หลัก'
    ],
    digit4WithDecimal (v) {
      if (v.length <= 0 || isNaN(parseInt(v))) {
        return ['กรุณากรอกข้อมูล']
      } else if (parseFloat(v) <= 0) {
        return ['จำนวนต้องมากกว่า 0']
      } else {
        var numberBFDecimal = v.split('.')
        if (parseFloat(numberBFDecimal[0]) > 9999.99) {
          return ['จำนวนต้องไม่เกิน 4 หลัก']
        }
      }
    },
    digit6WithDecimal (v) {
      if (v.length <= 0 || isNaN(parseInt(v))) {
        return ['กรุณากรอกข้อมูล']
      } else if (parseFloat(v) <= 0) {
        return ['จำนวนต้องมากกว่า 0']
      } else {
        var numberBFDecimal = v.split('.')
        if (parseFloat(numberBFDecimal[0]) > 999999.99) {
          return ['จำนวนต้องไม่เกิน 6 หลัก']
        }
      }
    },
    moreThanZero: [
      v => !!v || 'กรุณากรอกข้อมูล',
      v => (v && v >= 1) || 'จำนวนต้องมากกว่า 0'
    ],
    noRule (v) {
    },
    emptyInput: [v => !!v || 'กรุณากรอกข้อมูล'],
    totalOrder: [
      v => !isNaN(parseInt(v)) || 'กรุณากรอกข้อมูล'
    ],
    emptySelect: [v => !!v || 'กรุณาเลือกข้อมูล'],
    sku: [
      v => !!v || 'กรุณาระบุรหัสสินค้า',
      v => v.length <= 30 || 'กรุณาระบุรหัสสินค้าไม่เกิน 30 ตัวอักษร',
      v => v.length >= 6 || 'กรุณาระบุรหัสสินค้า 6 ตัวอักษรขึ้นไป'
    ],
    product_name: [
      v => !!v || 'กรุณาระบุชื่อสินค้า'
    ],
    Category: [
      v => v.length > 4 || 'กรุณาเลือกหมวดหมู่'
    ],
    linkYoutube (v) {
      // if (v.search('youtube') < 0 && v.length > 0) {
      if (!(v.includes('youtube') || v.includes('youtu.be')) && v.length > 0) {
        return ['กรุณาระบุลิงก์ยูทูป']
      }
    },
    linkYoutubeCantEmpty (v) {
      // if (v.search('youtube') < 0 && v.length > 0) {
      if (v === '') {
        return ['กรุณาระบุลิงก์ยูทูป']
      } else if (!(v.includes('youtube') || v.includes('youtu.be')) && v.length > 0) {
        return ['กรุณาระบุลิงก์ยูทูป']
      }
    },
    linkPDF (v) {
      if (!(v.includes('pdf')) && v.length > 0) {
        return ['กรุณาระบุลิงก์เอกสารนามสกุล pdf']
      }
    },
    checkMaxOrder (max, min) {
      if (parseInt(max) < parseInt(min)) {
        return 'ห้ามกรอกกำกับการสั่งซื้อมากสุดน้อยกว่ากำกับการสั่งซื้อน้อยสุด'
      } else if (isNaN(parseInt(max))) {
        return 'กรุณากรอกกำกับการสั่งซื้อมากสุด'
      }
    },
    float (value) {
      var valuecheck = value.toString()
      if (isNaN(parseInt(value))) {
        return 'กรุณาระบุราคาสินค้า'
      } else if (parseInt(value) === 0) {
        return 'กรุณาห้ามกรอกราคา 0 บาท'
      } else if (parseFloat(value) > parseFloat(100000000.00)) {
        return 'กรุณาระบุราคาสินค้าน้อยกว่า 100000000.00'
      } else if ((valuecheck.length - valuecheck.indexOf('.')) > 3 && valuecheck.indexOf('.') !== -1) {
        return 'กรุณาห้ามกรอกทศนิยมเกิน 2 ตำแหน่ง'
      }
    },
    selectTier: [
      v => v.length > 0 || 'กรุณาเลือกระดับคู่ค้า'
    ],
    attribute_name: [
      v => !!v || 'กรุณาระบุชื่อลักษณะ'
    ],
    attribute_value: [
      v => !!v || 'กรุณาระบุตัวเลือกสินค้า'
    ],
    tierPercent (v) {
      var checkType = v.toString()
      if (parseInt(v) > 99) {
        return 'ห้ามกรอกเกิน 100%'
      } else if ((checkType.length - checkType.indexOf('.')) > 3 && checkType.indexOf('.') !== -1) {
        return 'กรุณาห้ามกรอกทศนิยมเกิน 2 ตำแหน่ง'
      } else if (isNaN(parseInt(v))) {
        return 'กรุณาระบุส่วนลด'
      }
    },
    tier3: [
      v => !!v || 'กรุณาระบุราคาสินค้า tier 3',
      v => /^([0-9\W^ก-ฮ?*/s+])+$/.test(v) || 'รูปแบบไม่ถูกต้อง กรุณาระบุตัวเลขเท่านั้น'
    ],
    tier4: [
      v => !!v || 'กรุณาระบุราคาสินค้า tier 4',
      v => /^([0-9\W^ก-ฮ?*/s+])+$/.test(v) || 'รูปแบบไม่ถูกต้อง กรุณาระบุตัวเลขเท่านั้น'
    ],
    tier5: [
      v => !!v || 'กรุณาระบุราคาสินค้า tier 5',
      v => /^([0-9\W^ก-ฮ?*/s+])+$/.test(v) || 'รูปแบบไม่ถูกต้อง กรุณาระบุตัวเลขเท่านั้น'
    ],
    shipping_rate: [
      v => !!v || 'กรุณาระบุราคาค่าขนส่ง',
      v => /^([0-9\W^ก-ฮ?*/s+])+$/.test(v) || 'รูปแบบไม่ถูกต้อง กรุณาระบุตัวเลขเท่านั้น'
    ],
    ratio: [
      v => !!v || 'กรุณาระบุอัตราส่วนต่อหน่วย',
      v => /^([0-9\W^ก-ฮ?*/s+])+$/.test(v) || 'รูปแบบไม่ถูกต้อง กรุณาระบุตัวเลขเท่านั้น'
    ]
  }
}

export default itemRules
