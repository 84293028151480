import AxiosMemberClass from './axios_member_class'

const ModuleMemberClass = {
  state: {
    stateListMemberClass: [],
    stateDetailMemberClass: [],
    stateEditMemberClass: [],
    stateCreateMemberClass: [],
    stateDeleteMemberClass: []
  },
  mutations: {
    mutationsListMemberClass (state, data) {
      state.stateListMemberClass = data
    },
    mutationDetailMemberClass (state, data) {
      state.stateDetailMemberClass = data
    },
    mutationEditMemberClass (state, data) {
      state.stateEditMemberClass = data
    },
    mutationCreateMemberClass (state, data) {
      state.stateCreateMemberClass = data
    },
    mutationDeleteMemberClass (state, data) {
      state.stateDeleteMemberClass = data
    }
  },
  actions: {
    async actionsListMemberClass (context) {
      const response = await AxiosMemberClass.getListMemberClass()
      await context.commit('mutationsListMemberClass', response)
    },
    async actionDetailMemberClass (context, access) {
      const response = await AxiosMemberClass.axiosDetailMemberClass(access)
      await context.commit('mutationDetailMemberClass', response)
    },
    async actionEditMemberClass (context, access) {
      const response = await AxiosMemberClass.axiosEditMemberClass(access)
      await context.commit('mutationEditMemberClass', response)
    },
    async actionCreateMemberClass (context, access) {
      const response = await AxiosMemberClass.axiosCreateMemberClass(access)
      await context.commit('mutationCreateMemberClass', response)
    },
    async actionDeleteMemberClass (context, access) {
      const response = await AxiosMemberClass.axiosDeleteMemberClass(access)
      await context.commit('mutationDeleteMemberClass', response)
    }
  }
}

export default ModuleMemberClass
