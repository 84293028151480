import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosQuotationBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosQuotationDetailBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/detail_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListAddressUser (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateQuotation (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/create`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListProductQuotation () {
    const data = ''
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}quotation/list_product_price_by_cate`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetCancelQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/cancel_quotation`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListProductQuotationAll (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_active_product_list`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
