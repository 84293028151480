import AxiosManageFlashSale from './axios_manage_flash_sale'

const ModuleManageFlasSale = {
  state: {
    stateListFlasSaleSeller: [],
    stateDetailFlashSaleSeller: [],
    stateDetailForCreateFlashSale: [],
    stateCreateFlashSaleSeller: [],
    stateDeleteFlashSaleSeller: [],
    stateUpdateStatusFlashSaleSeller: [],
    stateGetForSelectFlashSaleSeller: [],
    stateExportFlashSale: [],
    stateEditFlashSaleSeller: []
  },
  mutations: {
    mutationListFlasSaleSeller (state, data) {
      state.stateListFlasSaleSeller = data
    },
    mutationDetailFlashSaleSeller (state, data) {
      state.stateDetailFlashSaleSeller = data
    },
    mutationDetailForCreateFlashSale (state, data) {
      state.stateDetailForCreateFlashSale = data
    },
    mutationCreateFlashSaleSeller (state, data) {
      state.stateCreateFlashSaleSeller = data
    },
    mutationDeleteFlashSaleSeller (state, data) {
      state.stateDeleteFlashSaleSeller = data
    },
    mutationUpdateStatusFlashSaleSeller (state, data) {
      state.stateUpdateStatusFlashSaleSeller = data
    },
    mutationEditFlashSaleSeller (state, data) {
      state.stateEditFlashSaleSeller = data
    },
    mutationGetForSelectFlashSaleSeller (state, data) {
      state.stateGetForSelectFlashSaleSeller = data
    },
    mutationExportFlashSale (state, data) {
      state.stateExportFlashSale = data
    }
  },
  actions: {
    async actionListFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.ListFlashSaleSeller(access)
      await context.commit('mutationListFlasSaleSeller', response)
    },
    async actionDetailFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.DetailFlashSaleSeller(access)
      await context.commit('mutationDetailFlashSaleSeller', response)
    },
    async actionDetailForCreateFlashSale (context, access) {
      const response = await AxiosManageFlashSale.DetailForCreateFlashSale(access)
      await context.commit('mutationDetailForCreateFlashSale', response)
    },
    async actionCreateFlashSaleSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.CreateFlashSaleSeller(access)
      await context.commit('mutationCreateFlashSaleSeller', response)
    },
    async actionDeleteFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.DeleteFlashSaleSeller(access)
      await context.commit('mutationDeleteFlashSaleSeller', response)
    },
    async actionUpdateStatusFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.UpdateStatusFlashSaleSeller(access)
      await context.commit('mutationUpdateStatusFlashSaleSeller', response)
    },
    async actionEditFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.EditFlashSaleSeller(access)
      await context.commit('mutationEditFlashSaleSeller', response)
    },
    async actionGetForSelectFlashSaleSeller (context, access) {
      const response = await AxiosManageFlashSale.GetForSelectFlashSaleSeller(access)
      await context.commit('mutationGetForSelectFlashSaleSeller', response)
    },
    async actionExportFlashSale (context, access) {
      const response = await AxiosManageFlashSale.ExportFlashSale(access)
      await context.commit('mutationExportFlashSale', response)
    }
  }
}

export default ModuleManageFlasSale
