import AxiosIship from '../store_iship_api/axios_iship_api'

const NSGModuleIship = {
  state: {
    // get courier for shop
    stateGetCourier: [],
    // set courier for shop
    stateSetCourier: [],
    // list order
    stateListOrder: [],
    // check price
    stateCheckPrice: [],
    // print shipping order
    statePrintShipping: [],
    // print PDF
    statePrintPDF: [],
    // create order
    stateCreateOrder: [],
    // cancel order
    // stateCancelOrder: [],
    stateCancelOrderIship: [],
    // list request courier
    stateListRequest: [],
    // request courier
    stateRequestCourier: [],
    // get courier for buyer
    stateGetCourierForBuyer: [],
    // list product in order,
    stateListProductInOrder: [],
    // list order for create transports
    stateListOrderCreateTransports: [],
    // create prepare order
    stateCreatePrepareOrder: [],
    // split prepare order
    stateSplitPrepareOrder: [],
    // create order from prepare_id
    stateCreateOrderFromPrepareID: [],
    // cut order
    stateCutOrder: [],
    // cut order
    stateSplitOrder: [],
    // cut order
    stateChangeCourier: [],
    // cut order
    stateChangeCourierNoTranspot: [],
    // cut order
    stateChangeCourierNoTranspotCourier: [],
    // get data
    stateDaTa: [],

    stateSendOrderBySeller: []
  },
  mutations: {
    // get courier for shop
    mutationsGetCourier (state, data) {
      state.stateGetCourier = data
    },
    // set courier for shop
    mutationsSetCourier (state, data) {
      state.stateSetCourier = data
    },
    // list order
    mutationsListOrder (state, data) {
      state.stateListOrder = data
    },
    // check price
    mutationsCheckPrice (state, data) {
      state.stateCheckPrice = data
    },
    // print shipping order
    mutationsPrintShipping (state, data) {
      state.statePrintShipping = data
    },
    // print PDF
    mutationsPrintPDF (state, data) {
      state.statePrintPDF = data
    },
    // create order
    mutationsCreateOrder (state, data) {
      state.stateCreateOrder = data
    },
    // cancel order
    // mutationsCancelOrder (state, data) {
    //   state.stateCancelOrder = data
    // },
    mutationsCancelOrderIship (state, data) {
      state.stateCancelOrderIship = data
    },
    // list request courier
    mutationsListRequest (state, data) {
      state.stateListRequest = data
    },
    // request courier
    mutationsRequestCourier (state, data) {
      state.stateRequestCourier = data
    },
    // get courier for buyer
    mutationsGetCourierForBuyer (state, data) {
      state.stateGetCourierForBuyer = data
    },
    // list product in order,
    mutationsListProductInOrder (state, data) {
      state.stateListProductInOrder = data
    },
    // list order for create transports
    mutationsListOrderCreateTransports (state, data) {
      state.stateListOrderCreateTransports = data
    },
    // create prepare order
    mutationsCreatePrepareOrder (state, data) {
      state.stateCreatePrepareOrder = data
    },
    // split prepare order
    mutationsSplitPrepareOrder (state, data) {
      state.stateSplitPrepareOrder = data
    },
    // create order from prepare_id
    mutationsCreateOrderFromPrepareID (state, data) {
      state.stateCreateOrderFromPrepareID = data
    },
    // cut order
    mutationsCutOrder (state, data) {
      state.stateCutOrder = data
    },
    // cut order
    mutationsSplitOrder (state, data) {
      state.stateSplitOrder = data
    },
    // cut order
    mutationsChangeCourier (state, data) {
      state.stateChangeCourier = data
    },
    mutationsChangeCourierNoTranspot (state, data) {
      state.stateChangeCourierNoTranspot = data
    },
    mutationsChangeCourierNoTranspotCourier (state, data) {
      state.stateChangeCourierNoTranspotCourier = data
    },
    // get data1
    mutationsDaTa (state, data) {
      state.stateDaTa = data
    },
    mutationsSendOrderBySeller (state, data) {
      state.stateSendOrderBySeller = data
    }
  },
  actions: {
    // get courier for shop
    async actionsGetCourier (context, access) {
      const response = await AxiosIship.GetCourier(access)
      await context.commit('mutationsGetCourier', response)
    },
    // set courier for shop
    async actionsSetCourier (context, access) {
      const response = await AxiosIship.SetCourier(access)
      await context.commit('mutationsSetCourier', response)
    },
    // list order
    async actionsListOrder (context, access) {
      const response = await AxiosIship.ListOrder(access)
      await context.commit('mutationsListOrder', response)
    },
    // check price
    async actionsCheckPrice (context, access) {
      const response = await AxiosIship.CheckPrice(access)
      await context.commit('mutationsCheckPrice', response)
    },
    // print shipping order
    async actionsPrintShipping (context, access) {
      const response = await AxiosIship.PrintShipping(access)
      await context.commit('mutationsPrintShipping', response)
    },
    // print PDF
    async actionsPrintPDF (context, access) {
      const response = await AxiosIship.PrintPDF(access)
      await context.commit('mutationsPrintPDF', response)
    },
    // create order
    async actionsCreateOrder (context, access) {
      const response = await AxiosIship.CreateOrder(access)
      await context.commit('mutationsCreateOrder', response)
    },
    // cancel order
    // async actionsCancelOrder (context, access) {
    //   const response = await AxiosIship.CancelOrder(access)
    //   await context.commit('mutationsCancelOrder', response)
    // },
    async actionsCancelOrderIship (context, access) {
      const response = await AxiosIship.CancelOrderIship(access)
      await context.commit('mutationsCancelOrderIship', response)
    },
    // list request courier
    async actionsListRequest (context, access) {
      const response = await AxiosIship.ListRequest(access)
      await context.commit('mutationsListRequest', response)
    },
    // request courier
    async actionsRequestCourier (context, access) {
      const response = await AxiosIship.RequestCourier(access)
      await context.commit('mutationsRequestCourier', response)
    },
    // get courier for buyer
    async actionsGetCourierForBuyer (context, access) {
      const response = await AxiosIship.GetCourierForBuyer(access)
      await context.commit('mutationsGetCourierForBuyer', response)
    },
    // list product in order,
    async actionsListProductInOrder (context, access) {
      const response = await AxiosIship.ListProductInOrder(access)
      await context.commit('mutationsListProductInOrder', response)
    },
    // list order for create transports
    async actionsListOrderCreateTransports (context, access) {
      const response = await AxiosIship.ListOrderCreateTransports(access)
      await context.commit('mutationsListOrderCreateTransports', response)
    },
    // create prepare order
    async actionsCreatePrepareOrder (context, access) {
      const response = await AxiosIship.CreatePrepareOrder(access)
      await context.commit('mutationsCreatePrepareOrder', response)
    },
    // split prepare order
    async actionsSplitPrepareOrder (context, access) {
      const response = await AxiosIship.SplitPrepareOrder(access)
      await context.commit('mutationsSplitPrepareOrder', response)
    },
    // create order from prepare_id
    async actionsCreateOrderFromPrepareID (context, access) {
      const response = await AxiosIship.CreateOrderFromPrepareID(access)
      await context.commit('mutationsCreateOrderFromPrepareID', response)
    },
    // cut order
    async actionsCutOrder (context, access) {
      const response = await AxiosIship.CutOrder(access)
      await context.commit('mutationsCutOrder', response)
    },
    // split order
    async actionsSplitOrder (context, access) {
      const response = await AxiosIship.SplitOrder(access)
      await context.commit('mutationsSplitOrder', response)
    },
    async actionsChangeCourier (context, access) {
      const response = await AxiosIship.ChangeCourier(access)
      await context.commit('mutationsChangeCourier', response)
    },
    async actionsChangeCourierNoTranspot (context, access) {
      const response = await AxiosIship.ChangeCourierNoTranspot(access)
      await context.commit('mutationsChangeCourierNoTranspot', response)
    },
    async actionsChangeCourierNoTranspotCourier (context, access) {
      const response = await AxiosIship.ChangeCourierNoTranspotCourier(access)
      await context.commit('mutationsChangeCourierNoTranspotCourier', response)
    },
    // get data
    async actionsGetDaTa (context, access) {
      const response = await AxiosIship.DaTa(access)
      await context.commit('mutationsDaTa', response)
    },
    // get data
    async actionsSendOrderBySeller (context, access) {
      const response = await AxiosIship.SendOrderBySeller(access)
      await context.commit('mutationsSendOrderBySeller', response)
    }
  }
}

export default NSGModuleIship
