import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import Antd from 'ant-design-vue'
import VueThailandAddress from 'vue-thailand-address'
import draggable from 'vuedraggable'
import VueHorizontalList from 'vue-horizontal-list'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'ant-design-vue/dist/antd.css'
import 'vue-thailand-address/dist/vue-thailand-address.css'
import { VueperSlides, VueperSlide } from 'vueperslides'
import { CoolSelectPlugin } from 'vue-cool-select'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueClipboard from 'vue-clipboard2'
// import VueMeta from 'vue-meta'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-cool-select/dist/themes/material-design.css'
import 'vue-cool-select/dist/themes/bootstrap.css'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import VueExcelXlsx from 'vue-excel-xlsx'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import Media from '@dongido/vue-viaudio'
import VueSocialSharing from 'vue-social-sharing'
import VueSocials from 'vue-socials'
import VueFacebookPixel from 'vue-facebook-pixel'
import VueJsonLD from 'vue-jsonld'
import VueSnip from 'vue-snip'
import Skeleton from 'vue-loading-skeleton'
import VueI18n from 'vue-i18n'
// import translations
import th from './locales/th.js'
import en from './locales/en.js'
import VueApexCharts from 'vue-apexcharts'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import mapInit from 'highcharts/modules/map'
import mapData from '../src/components/library/mapTH.json'
import VueYoutube from 'vue-youtube'

// import '@mdi/font/css/materialdesignicons.min.css'
Vue.use(VueFacebookPixel)
Vue.use(VueYoutube)
Vue.use(VueExcelXlsx)
VueClipboard.config.autoSetContainer = true
Vue.use(Skeleton)
Vue.use(VueJsonLD)
Vue.use(VueSnip)
Vue.use(VueSocialSharing)
mapInit(Highcharts)
Highcharts.maps[' MapTH '] = mapData
Vue.use(HighchartsVue)
Vue.use(VueSocials)
Vue.use(VueFileAgent, VueFileAgentStyles)
Vue.use(CoolSelectPlugin)
Vue.use(CKEditor)
Vue.component('vueper-slides', VueperSlides)
Vue.component('vueper-slide', VueperSlide)
Vue.component('vue-qrcode', VueQrcode)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
Vue.use(VueCookies)
Vue.use(Antd)
Vue.use(VueThailandAddress)
Vue.use(draggable)
Vue.use(VueHorizontalList)
Vue.use(VueClipboard)
// Vue.use(VueMeta, { refreshOnceOnNavigation: true })
Vue.use(VueMask)
Vue.use(Media)
Vue.use(VueI18n)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
Vue.config.devtools = true
Vue.config.performance = true

export const i18n = new VueI18n({
  locale: 'th',
  messages: { th, en }
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  VueSlickCarousel,
  render: function (h) { return h(App) }
}).$mount('#app')
