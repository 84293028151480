import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // get courier for shop
  async GetCourier (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}shop/get_courier`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // set courier for shop
  async SetCourier (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}set/set_courier_for_shop`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list order
  async ListOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}order/list_order`, val, auth)
      return response.data
    } catch (error) {
      return 'CORS'
    }
  },
  // list order
  async DaTa (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}data`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // check price
  async CheckPrice (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}check_price`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // print shipping order
  async PrintShipping (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}order/print_shipping_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // print PDF
  async PrintPDF (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}print_label/pdf`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create order
  // async CreateOrder (val) {
  //   const auth = await GetToken()
  //   try {
  //     var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}create_order`, val, auth)
  //     return response.data
  //   } catch (error) {
  //     return error.response.data
  //   }
  // },
  // cancel order
  async CancelOrderIship (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}cancel_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list request courier
  async ListRequest (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}courier/list_request_courier`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // request courier
  async RequestCourier (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}courier/request`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // get courier for buyer
  async GetCourierForBuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}buyer/get_courier`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list product in order,
  async ListProductInOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}order/list_product_in_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list order for create transports
  async ListOrderCreateTransports (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}order/list_order_for_create_transports`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create prepare order
  async CreatePrepareOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}prepare_order/create`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // split prepare order
  async SplitPrepareOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}prepare_order/split`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create order from prepare_id
  async CreateOrderFromPrepareID (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}create_order/prepare_order_id`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // cut order
  async CutOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}prepare_order/split`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeCourier (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}courier/change/prepare_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeCourierNoTranspot (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}courier/change`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeCourierNoTranspotCourier (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}courier/change/courier`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // cut order
  async SplitOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END_ISHIP}order/split_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SendOrderBySeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}send_order_by_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
