import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosListPromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListPromotionNoCam (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_promotion_no_campaign`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreatePromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/create_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditPromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/edit_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailPromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/detail_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeletePromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/delete_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCollectPromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/collect_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetCenterPoint (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/get_point_per_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditCenterPoint (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/edit_point_per_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateNGSImage (val) {
    const data = val
    const auth = {
      headers: { token: 'token_ngs_dev' }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_IMAGE_NGS}`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
