import AxiosDashboard from './axios_dashboard'

const ModuleNSGDashboard = {
  state: {
    // dashboard/buyer
    stateBuyer: [],
    // dashboard/purchaseAmount,
    statePurchaseAmount: [],
    // /dashboard/howManyOrder
    stateHowManyOrder: [],
    // /dashboard/revenue
    stateRevenue: [],
    // /dashboard/sale
    stateSale: [],
    // dashboard/top10Product
    stateTop10Product: [],
    // /dashboard/top10Buyer,
    stateTop10Value: [],
    // /dashboard/top10Buyer,
    stateTop10Buyer: [],
    // /dashboard/orderList
    stateOrderList: [],
    // /dashboard/dashboardExportExcel/start_date/end_date/shop_id
    stateExport: [],
    stateTransportMap: [],
    stateTransportData: [],
    stateTransportExport: [],
    // api/ngs/dashboard_new_member
    stateDashboardNewMenber: [],

    // < ------------------------ DashBoard Campaign ------------------------ >
    // /campaignDB/purchaseInMonth
    stateCampaignPurchase: [],
    // ngs/list_member_class
    stateListMemberClassDashboard: [],
    // /campaignDB/memberClass
    stateCampaignMemberClass: [],
    // /campaignDB/top10Product
    stateCampaignTop10Product: [],
    // api/ngs/dashboard_campaign_chart
    stateDashboardCampaignChart: [],
    // api/ngs/dashboard_campaign_table
    stateDashboardCampaignTable: [],
    // api/ngs/dashboard_campaign_total
    stateDashboardCampaignTotal: [],
    // < ------------------------ DashBoard Comment ------------------------ >
    stateDashboardOrderComment: [],
    stateDashboardComment: [],
    // < ------------------------ DashBoard Vocher ------------------------ >
    stateDashboardVocherHead: [],
    stateDashboardVoucherList: [],
    stateDashboardVoucherPurchase: [],
    stateDashboardtop10BestSellerVoucher: [],
    stateDashboardtop10MostUseVoucher: []
  },
  mutations: {
    // dashboard/buyer
    mutationsBuyer (state, data) {
      state.stateBuyer = data
    },
    // dashboard/purchaseAmount,
    mutationsPurchaseAmount (state, data) {
      state.statePurchaseAmount = data
    },
    // /dashboard/howManyOrder
    mutationsHowManyOrder (state, data) {
      state.stateHowManyOrder = data
    },
    // /dashboard/revenue
    mutationsRevenue (state, data) {
      state.stateRevenue = data
    },
    // /dashboard/sale
    mutationsSale (state, data) {
      state.stateSale = data
    },
    // dashboard/top10Product
    mutationsTop10Product (state, data) {
      state.stateTop10Product = data
    },
    // dashboard/top10Product
    mutationsTop10Value (state, data) {
      state.stateTop10Value = data
    },
    // /dashboard/top10Buyer,
    mutationsTop10Buyer (state, data) {
      state.stateTop10Buyer = data
    },
    // /dashboard/orderList
    mutationsOrderList (state, data) {
      state.stateOrderList = data
    },
    // /dashboard/dashboardExportExcel/start_date/end_date/shop_id
    mutationsExport (state, data) {
      state.stateExport = data
    },
    mutationsTransportMap (state, data) {
      console.log('เข้า mut', data)
      state.stateTransportMap = data
      console.log('state', state.stateTransportMap)
    },
    mutationsTransportData (state, data) {
      state.stateTransportData = data
    },
    mutationsTransportExport (state, data) {
      state.stateTransportExport = data
    },
    // api/ngs/dashboard_new_member
    mutationsDashboardNewMenber (state, data) {
      state.stateDashboardNewMenber = data
    },

    // < ------------------------ DashBoard Campaign ------------------------ >
    // /campaignDB/purchaseInMonth
    mutationsCampaignPurchase (state, data) {
      state.stateCampaignPurchase = data
    },
    // ngs/list_member_class
    mutationsListMemberClassDashboard (state, data) {
      state.stateListMemberClassDashboard = data
    },
    // /campaignDB/memberClass
    mutationsCampaignMemberClass (state, data) {
      state.stateCampaignMemberClass = data
    },
    // /campaignDB/top10Product
    mutationsCampaignTop10Product (state, data) {
      state.stateCampaignTop10Product = data
    },
    // api/ngs/dashboard_campaign_chart
    mutationsDashboardCampaignChart (state, data) {
      state.stateDashboardCampaignChart = data
    },
    // api/ngs/dashboard_campaign_table
    mutationsDashboardCampaignTable (state, data) {
      state.stateDashboardCampaignTable = data
    },
    // api/ngs/dashboard_campaign_total
    mutationsDashboardCampaignTotal (state, data) {
      state.stateDashboardCampaignTotal = data
    },

    // < ------------------------ DashBoard Comment ------------------------ >
    mutationsDashboardOrderComment (state, data) {
      state.stateDashboardOrderComment = data
    },
    mutationsDashboardComment (state, data) {
      state.stateDashboardComment = data
    },
    // < ------------------------ DashBoard Voucher ------------------------ >
    mutationsDashboardVoucherHead (state, data) {
      state.stateDashboardVocherHead = data
    },
    mutationsDashboardVoucherList (state, data) {
      state.stateDashboardVoucherList = data
    },
    mutationsDashboardVoucherPurchase (state, data) {
      state.stateDashboardVoucherPurchase = data
    },
    mutationsDashboardtop10BestSellerVoucher (state, data) {
      state.stateDashboardtop10BestSellerVoucher = data
    },
    mutationsDashboardtop10MostUseVoucher (state, data) {
      state.stateDashboardtop10MostUseVoucher = data
    }
  },
  actions: {
    // dashboard/buyer
    async actionsBuyer (context, access) {
      const response = await AxiosDashboard.getBuyer(access)
      await context.commit('mutationsBuyer', response)
    },
    // dashboard/purchaseAmount,
    async actionsPurchaseAmount (context, access) {
      const response = await AxiosDashboard.PurchaseAmount(access)
      await context.commit('mutationsPurchaseAmount', response)
    },
    // /dashboard/howManyOrder
    async actionsHowManyOrder (context, access) {
      const response = await AxiosDashboard.HowManyOrder(access)
      await context.commit('mutationsHowManyOrder', response)
    },
    // /dashboard/revenue
    async actionsRevenue (context, access) {
      const response = await AxiosDashboard.getRevenue(access)
      await context.commit('mutationsRevenue', response)
    },
    // /dashboard/sale
    async actionsSale (context, access) {
      const response = await AxiosDashboard.getSale(access)
      await context.commit('mutationsSale', response)
    },
    // dashboard/top10Product
    async actionsTop10Product (context, access) {
      const response = await AxiosDashboard.Top10Product(access)
      await context.commit('mutationsTop10Product', response)
    },
    // dashboard/top10Product
    async actionsTop10Value (context, access) {
      const response = await AxiosDashboard.Top10Value(access)
      await context.commit('mutationsTop10Value', response)
    },
    // /dashboard/top10Buyer,
    async actionsTop10Buyer (context, access) {
      const response = await AxiosDashboard.Top10Buyer(access)
      await context.commit('mutationsTop10Buyer', response)
    },
    // /dashboard/orderList
    async actionsOrderList (context, access) {
      const response = await AxiosDashboard.getOrderList(access)
      await context.commit('mutationsOrderList', response)
    },
    // /dashboard/dashboardExportExcel/start_date/end_date/shop_id
    async actionsExport (context, access) {
      const response = await AxiosDashboard.getExport(access)
      await context.commit('mutationsExport', response)
    },
    async actionsTransportMap (context, access) {
      const response = await AxiosDashboard.TransportMap(access)
      await context.commit('mutationsTransportMap', response)
    },
    async actionsTransportData (context, access) {
      const response = await AxiosDashboard.TransportData(access)
      await context.commit('mutationsTransportData', response)
    },
    async actionsTransportExport (context, access) {
      const response = await AxiosDashboard.TransportExport(access)
      await context.commit('mutationsTransportExport', response)
    },
    // api/ngs/dashboard_new_member
    async actionsDashboardNewMenber (context, access) {
      const response = await AxiosDashboard.DashboardNewMenber(access)
      await context.commit('mutationsDashboardNewMenber', response)
    },
    // < ------------------------ DashBoard Campaign ------------------------ >
    // /campaignDB/purchaseInMonth
    async actionsCampaignPurchase (context, access) {
      const response = await AxiosDashboard.CampaignPurchase(access)
      await context.commit('mutationsCampaignPurchase', response)
    },
    // /campaignDB/memberClass
    async actionsCampaignMemberClass (context, access) {
      const response = await AxiosDashboard.CampaignMemberClass(access)
      await context.commit('mutationsCampaignMemberClass', response)
    },
    // ngs/list_member_class
    async actionsListMemberClassDashboard (context, access) {
      const response = await AxiosDashboard.ListMemberClassDashboard(access)
      await context.commit('mutationsListMemberClassDashboard', response)
    },
    // /campaignDB/top10Product
    async actionsCampaignTop10Product (context, access) {
      const response = await AxiosDashboard.CampaignTop10Product(access)
      await context.commit('mutationsCampaignTop10Product', response)
    },
    // api/ngs/dashboard_campaign_chart
    async actionsDashboardCampaignChart (context, access) {
      const response = await AxiosDashboard.DashboardCampaignChart(access)
      await context.commit('mutationsDashboardCampaignChart', response)
    },
    // api/ngs/dashboard_campaign_table
    async actionsDashboardCampaignTable (context, access) {
      const response = await AxiosDashboard.DashboardCampaignTable(access)
      await context.commit('mutationsDashboardCampaignTable', response)
    },
    // api/ngs/dashboard_campaign_total
    async actionsDashboardCampaignTotal (context, access) {
      const response = await AxiosDashboard.DashboardCampaignTotal(access)
      await context.commit('mutationsDashboardCampaignTotal', response)
    },
    async actionsDashboardOrderComment (context, access) {
      const response = await AxiosDashboard.DashboardOrderComment(access)
      await context.commit('mutationsDashboardOrderComment', response)
    },
    async actionsDashboardComment (context, access) {
      const response = await AxiosDashboard.DashboardComment(access)
      await context.commit('mutationsDashboardComment', response)
    },
    //
    async actionsDashboardVoucherHead (context, access) {
      const response = await AxiosDashboard.DashboardVoucherHead(access)
      await context.commit('mutationsDashboardVoucherHead', response)
    },
    async actionsDashboardVoucherList (context, access) {
      const response = await AxiosDashboard.DashboardVoucherList(access)
      await context.commit('mutationsDashboardVoucherList', response)
    },
    async actionsDashboardVoucherPurchase (context, access) {
      const response = await AxiosDashboard.DashboardVoucherPurchase(access)
      await context.commit('mutationsDashboardVoucherPurchase', response)
    },
    async actionsDashboardtop10BestSellerVoucher (context, access) {
      const response = await AxiosDashboard.Dashboardtop10BestSellerVoucher(access)
      await context.commit('mutationsDashboardtop10BestSellerVoucher', response)
    },
    async actionsDashboardtop10MostUseVoucher (context, access) {
      const response = await AxiosDashboard.Dashboardtop10MostUseVoucher(access)
      await context.commit('mutationsDashboardtop10MostUseVoucher', response)
    }
  }
}

export default ModuleNSGDashboard
