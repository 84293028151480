import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import ModuleGlobal from './store_global'
// import ModuleHompage from './store_homepage_api/vuex_homepage_api'
// import ModuleProduct from './store_product_api/vuex_product_api'
// import ModuleShop from './store_shop/vuex_shop_api'
// import ModuleCart from './store_Cart/vuex_cart_api'
import ModuleManageShop from './store_manage_shop/vuex_manage_api'
// import ModuleUser from './store_user/vuex_user_api'
// import ModuleOrder from './store_order/vuex_order_api'
// import ModuleBusiness from './store_Business/vuex_business'
// import ModuleRegister from './store_Register/vuex_register_api'
import ModuleInventory from './store_inventory/vuex_inventory_api'
// Import store of UPS Project
import UPSModuleUser from './UPS/store_user/vuex_ups_user_api'
// import UPSModulePromotion from './UPS/store_promotion/vuex_ups_promotion_api'
// import UPSModuleIndustry from './UPS/store_industry/vuex_ups_industry_api'
// import UPSModuleCategory from './UPS/store_category/vuex_ups_category_api'
// import UPSModuleBrand from './UPS/store_brand/vuex_ups_brand_api'
// import UPSModuleOrder from './UPS/store_order/vuex_ups_order_api'
// import UPSModuleNewsActivity from './UPS/store_news&activity/vuex_ups_news&activity_api'
// import UPSModuleShop from './UPS/store_shop/vuex_ups_shop_api'
// import UPSModuleProduct from './UPS/Store_product/vuex_ups_product_api'
// import UPSModuleProductSearch from './UPS/store_ product_search/vuex_ups_product_search_api'
// import UPSModuleCart from './UPS/store_cart/vuex_ups_cart_api'
// import UPSModuleFavoriteProduct from './UPS/store_favorite_product/vuex_ups_favorite_product_api'
// import UPSModuleCatalog from './UPS/store_catalog/vuex_ups_catalog_api'
// import UPSModuleThirdParty from './UPS/store_for_third_party/vuex_ups_for_third_party_api'
// import UPSModuleFlash from './UPS/store_flash_call/vuex_callflash_api'
// import UPSModuleCustomer from './UPS/store_customer/vues_ups_customer'
import itemRules from './product_data/Rules'
import ModuleManageNews from './NSG/store_manage_news/vuex_manage_news'
import ModuleManageProduct from './NSG/store_manage_product/vuex_manage_product'
import ModuleCategory from './NSG/store_category/vuex_category'
import NSGModuleIship from './NSG/store_iship_api/vuex_iship_api'
import NSGModuleShop from './NSG/store_shop/vuex_shop_api'
import NSGModuleHomepage from './NSG/store_homepage/vuex_homepage_api'
import NSGModuleCampaign from './NSG/store_campaign/vuex_campaign'
import NSGModulePromotion from './NSG/store_promotion/vuex_promotion'
import NSGModuleMember from './NSG/store_member&privilege/vuex_member&privilege'
import NSGModuleCart from './NSG/store_cart/vuex_cart_api'
import NSGModuleMemberClass from './NSG/store_member_class/vuex_member_class'
import NSGModuleManageVoucher from './NSG/store_manage_voucher/vuex_manage_voucher'
import ModuleNSGDashboard from './NSG/store_dashboard/vuex_dashboard'
import NSGModuleManageComments from './NSG/store_manage_comments/vuex_manage_comments'
import NSGModuleReviewBuyer from './NSG/store_order_review/vuex_order_review'
import NSGModuleAcceptPDPA from './NSG/store_PDPA/vuex_PDPA'
import NSGManageLandingPage from './NSG/store_manage_landing_page/vuex_manage_landing_page'
import NSGManageReturn from './NSG/store_manage_return/vuex_manage_return'
import NSGModuleLog from './NSG/store_log/vuex_log_api'
import NSGFlashSale from './NSG/store_flash_sale/vuex_flashsale_api'
import NSGManageFlashSale from './NSG/store_manage_flash_sale/vuex_manage_flash_sale'
import NSGManagePermission from './NSG/store_permisssion/vuex_permission'
import NSGSaleMan from './NSG/store_sale_man/vuex_sale_man'
import NSGQuotation from './NSG/store_quotation/vuex_quotation'
import NSGManageQuotation from './NSG/store_manage_qoutation/vuex_manage_quotation'
import NSGUserManual from './NSG/store_user_manual/vuex_user_manual'
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    activeGrid: 1,
    tab: '1',
    EcatelogDialog: false
  },
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage
  // })],
  mutations: {
  },
  actions: {
  },
  modules: {
    ModuleGlobal,
    // ModuleHompage,
    // ModuleProduct,
    // ModuleShop,
    // ModuleCart,
    ModuleManageShop,
    // ModuleUser,
    // ModuleOrder,
    // ModuleBusiness,
    // ModuleRegister,
    ModuleInventory,
    // Import store of UPS Project
    UPSModuleUser,
    // UPSModulePromotion,
    // UPSModuleIndustry,
    // UPSModuleCategory,
    // UPSModuleBrand,
    // UPSModuleOrder,
    // UPSModuleNewsActivity,
    // UPSModuleShop,
    // UPSModuleProduct,
    // UPSModuleProductSearch,
    // UPSModuleCart,
    // UPSModuleFavoriteProduct,
    // UPSModuleCatalog,
    // UPSModuleThirdParty,
    // UPSModuleFlash,
    // UPSModuleCustomer,
    itemRules,
    ModuleManageNews,
    ModuleManageProduct,
    ModuleCategory,
    NSGModuleIship,
    NSGModuleShop,
    NSGModuleHomepage,
    NSGModuleCampaign,
    NSGModulePromotion,
    NSGModuleMember,
    NSGModuleCart,
    NSGModuleMemberClass,
    NSGModuleManageVoucher,
    ModuleNSGDashboard,
    NSGModuleManageComments,
    NSGModuleReviewBuyer,
    NSGModuleAcceptPDPA,
    NSGManageLandingPage,
    NSGManageReturn,
    NSGModuleLog,
    NSGFlashSale,
    NSGManageFlashSale,
    NSGManagePermission,
    NSGSaleMan,
    NSGQuotation,
    NSGManageQuotation,
    NSGUserManual
  }
})
