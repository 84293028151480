import AxiosOrderReview from './axios_order_review'
const NSGModuleReviewBuyer = {
  state: {
    stateCheckAcceptProduct: [],
    stateUpdateAcceptProduct: [],
    stateCreateCommentReview: [],
    stateCheckListReview: [],
    stateListAllRatingOrder: [],
    stateDetailCommentBuyer: []
  },
  mutations: {
    mutationCheckAcceptProduct (state, data) {
      state.stateCheckAcceptProduct = data
    },
    mutationUpdateAcceptProduct (state, data) {
      state.stateUpdateAcceptProduct = data
    },
    mutationCreateCommentReview (state, data) {
      state.stateCreateCommentReview = data
    },
    mutationCheckListReview (state, data) {
      state.stateCheckListReview = data
    },
    mutationListAllRatingOrder (state, data) {
      state.stateListAllRatingOrder = data
    },
    mutationDetailCommentBuyer (state, data) {
      state.stateDetailCommentBuyer = data
    }
  },
  actions: {
    async actionCheckAcceptProduct (context, access) {
      const response = await AxiosOrderReview.axiosCheckAcceptProduct(access)
      await context.commit('mutationCheckAcceptProduct', response)
    },
    async actionUpdateAcceptProduct (context, access) {
      const response = await AxiosOrderReview.axiosUpdateAcceptProduct(access)
      await context.commit('mutationUpdateAcceptProduct', response)
    },
    async actionCreateCommentReview (context, access) {
      const response = await AxiosOrderReview.axiosCreateCommentReview(access)
      await context.commit('mutationCreateCommentReview', response)
    },
    async actionCheckListReview (context, access) {
      const response = await AxiosOrderReview.axiosCheckListReview(access)
      await context.commit('mutationCheckListReview', response)
    },
    async actionListAllRatingOrder (context, access) {
      const response = await AxiosOrderReview.axiosListAllRatingOrder(access)
      await context.commit('mutationListAllRatingOrder', response)
    },
    async actionDetailCommentBuyer (context, access) {
      const response = await AxiosOrderReview.axiosDetailCommentBuyer(access)
      await context.commit('mutationDetailCommentBuyer', response)
    }
  }
}

export default NSGModuleReviewBuyer
