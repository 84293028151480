import AxiosUser from './axios_ups_user_api'

const UPSModuleUser = {
  state: {
    // User Detail Page
    stateUPSUserDetailPage: [],
    stateUPSBindAccount: [],
    stateUPSChangePasswordMarket: [],
    // Redirect ResetPassword Auth
    stateUPSRedirectResetPasswordAuth: [],
    // ResetPassword
    stateUPSResetPassword: [],
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    stateUPSCreatedAddressTaxinvoice: [],
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    stateUPSGetAddressTaxinvoice: [],
    stateUPSGetAddressTaxinvoicePurchase: [],
    // Detail Business ID
    stateUPSDetailBusiness: [],
    stateUPSUpdateUserProfile: [],
    stateUPSListUserAddress: [],
    stateUPSUpdateUserAddress: [],
    stateUPSListUserAddressTex: [],
    // Forget Password of market
    stateUPSForgetPassword: [],
    stateAddUserAddress: [],
    stateUpdateUserEmailAndPhone: [],
    stateDeleteInvoiceAddress: []
  },
  getters: {
    getterPermisstion: (state) => {
      var w = state.stateUPSUserDetailPage.data
      return w
    }
  },
  mutations: {
    // User Detail Page
    mutationsUPSUserDetailPage (state, data) {
      state.stateUPSUserDetailPage = data
    },
    // Bind Account and Auto Add OneChat Bot + Create Shop
    mutationsUPSBindAccount (state, data) {
      state.stateUPSBindAccount = data
    },
    // Change Password Market
    mutationsUPSChangePasswordMarket (state, data) {
      state.stateUPSChangePasswordMarket = data
    },
    // Redirect ResetPassword Auth
    mutationsUPSRedirectResetPasswordAuth (state, data) {
      state.stateUPSRedirectResetPasswordAuth = data
    },
    // ResetPassword
    mutationsUPSResetPassword (state, data) {
      state.stateUPSResetPassword = data
    },
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    mutationsUPSCreatedAddressTaxinvoice (state, data) {
      state.stateUPSCreatedAddressTaxinvoice = data
    },
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    mutationsUPSGetAddressTaxinvoice (state, data) {
      state.stateUPSGetAddressTaxinvoice = data
    },
    mutationsUPSGetAddressTaxinvoicePurchase (state, data) {
      state.stateUPSGetAddressTaxinvoicePurchase = data
    },
    // Detail Business ID
    mutationUPSDetailBusiness (state, data) {
      state.stateUPSDetailBusiness = data
    },
    mutationUPSUpdateUserProfile (state, data) {
      state.stateUPSUpdateUserProfile = data
    },
    mutationUPSListUserAddress (state, data) {
      state.stateUPSListUserAddress = data
    },
    mutationUPSUpdateUserAddress (state, data) {
      state.stateUPSUpdateUserAddress = data
    },
    mutationUPSListUserAddressTex (state, data) {
      state.stateUPSListUserAddressTex = data
    },
    // Forget Password of market
    mutationUPSForgetPassword (state, data) {
      state.stateUPSForgetPassword = data
    },
    mutationAddUserAddress (state, data) {
      state.stateAddUserAddress = data
    },
    mutationDeleteUserAddress (state, data) {
      state.stateDeleteUserAddress = data
    },
    mutationUpdateUserEmailAndPhone (state, data) {
      state.stateUpdateUserEmailAndPhone = data
    },
    mutationDeleteInvoiceAddress (state, data) {
      state.stateDeleteInvoiceAddress = data
    }
  },
  actions: {
    // User Detail Page
    async actionsNSGUserDetailPage (context) {
      const responseData = await AxiosUser.GetUPSUserDetailPage()
      // console.log('response', responseData)
      await context.commit('mutationsUPSUserDetailPage', responseData)
    },
    // Bind Account and Auto Add OneChat Bot + Create Shop
    async actionUPSBindAccount (context, access) {
      const responseData = await AxiosUser.SetUPSBindAccount(access)
      await context.commit('mutationsUPSBindAccount', responseData)
    },
    // Change Password Market
    async actionUPSChangePasswordMarket (context, access) {
      const responseData = await AxiosUser.ChangeUPSPasswordMarket(access)
      await context.commit('mutationsUPSChangePasswordMarket', responseData)
    },
    // Redirect ResetPassword Auth
    async actionUPSRedirectResetPasswordAuth (context, access) {
      const responseData = await AxiosUser.RedirectResetPasswordAuth(access)
      await context.commit('mutationsUPSRedirectResetPasswordAuth', responseData)
    },
    // ResetPassword
    async actionUPSResetPassword (context, access) {
      const responseData = await AxiosUser.ResetPassword(access)
      await context.commit('mutationsUPSResetPassword', responseData)
    },
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    async actionUPSCreatedAddressTaxinvoice (context, access) {
      const responseData = await AxiosUser.CreatedAddressTaxinvoice(access)
      await context.commit('mutationsUPSCreatedAddressTaxinvoice', responseData)
    },
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    async actionUPSGetAddressTaxinvoice (context, access) {
      const responseData = await AxiosUser.GetAddressTaxinvoice(access)
      await context.commit('mutationsUPSGetAddressTaxinvoice', responseData)
    },
    async actionUPSGetAddressTaxinvoicePurchase (context) {
      const responseData = await AxiosUser.GetAddressTaxinvoicePurchase()
      await context.commit('mutationsUPSGetAddressTaxinvoicePurchase', responseData)
    },
    // Detail Business ID
    async actionUPSDetailBusiness (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSDetailBusiness(access)
      await context.commit('mutationUPSDetailBusiness', dataFromAxios)
    },
    async actionUPSUpdateUserProfile (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSUpdateUserProfile(access)
      await context.commit('mutationUPSUpdateUserProfile', dataFromAxios)
    },
    async actionUPSListUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSListUserAddress(access)
      await context.commit('mutationUPSListUserAddress', dataFromAxios)
    },
    async actionUPSUpdateUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSUpdateUserAddress(access)
      await context.commit('mutationUPSUpdateUserAddress', dataFromAxios)
    },
    async actionUPSListUserAddressTex (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSListUserAddressTex(access)
      await context.commit('mutationUPSListUserAddressTex', dataFromAxios)
    },
    // Forget Password of market
    async actionUPSForgetPassword (context, access) {
      // console.log(access)
      const responseData = await AxiosUser.axiosUPSForgetPassword(access)
      await context.commit('mutationUPSForgetPassword', responseData)
    },
    async actionAddUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosAddUserAddress(access)
      await context.commit('mutationAddUserAddress', dataFromAxios)
    },
    async actionDeleteUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosDeleteUserAddress(access)
      await context.commit('mutationDeleteUserAddress', dataFromAxios)
    },
    async actionUpdateUserEmailAndPhone (context, access) {
      const dataFromAxios = await AxiosUser.axiosUpdateUserEmailAndPhone(access)
      await context.commit('mutationUpdateUserEmailAndPhone', dataFromAxios)
    },
    async actionDeleteInvoiceAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosDeleteInvoiceAddress(access)
      await context.commit('mutationDeleteInvoiceAddress', dataFromAxios)
    }
  }
}
export default UPSModuleUser
