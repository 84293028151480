import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosPointUser (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/point_user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/calculate_member_class_user`, data, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/calculate_member_class_user_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosPromotionUser (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/promotion_user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteCampaign (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}delete_campaign`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListMemberClassUser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_member_class_user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditMemberClassUser (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/edit_member_class_user`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosPointUserForAdmin (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/point_user_by_admin`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSendPointUser (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/send_point_user_by_admin`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosTransferPoint (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/transfer_point`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailMemberUser (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/detail_member_user`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
