import AxiosManageComments from './axios_manage_comments'
const NSGModuleManageComments = {
  state: {
    stateListAllReviewForSeller: [],
    stateDetailReviewForSeller: [],
    stateUpdateStatusComment: [],
    stateSetPoints: [],
    stateSetPointStatus: [],
    stateSetCommentStatus: [],
    stateSetSellerList: [],
    stateSellerDetail: [],
    stateLogList: [],
    stateSeller_manage: []
  },
  mutations: {
    mutationListAllReviewForSeller (state, data) {
      state.stateListAllReviewForSeller = data
    },
    mutationDetailReviewForSeller (state, data) {
      state.stateDetailReviewForSeller = data
    },
    mutationUpdateStatusComment (state, data) {
      state.stateUpdateStatusComment = data
    },
    mutationSetPoints (state, data) {
      state.stateSetPoints = data
    },
    mutationSetPointStatus (state, data) {
      state.stateSetPointStatus = data
    },
    mutationSetCommentStatus (state, data) {
      state.stateSetCommentStatus = data
    },
    mutationSetSellerList (state, data) {
      state.stateSetSellerList = data
    },
    mutationSellerDetail (state, data) {
      state.stateSellerDetail = data
    },
    mutationLogList (state, data) {
      state.stateLogList = data
    },
    mutationSeller_manage (state, data) {
      state.stateSeller_manage = data
    }
  },
  actions: {
    async actionListAllReviewForSeller (context, access) {
      const response = await AxiosManageComments.axiosListAllReviewForSeller(access)
      await context.commit('mutationListAllReviewForSeller', response)
    },
    async actionDetailReviewForSeller (context, access) {
      const response = await AxiosManageComments.axiosDetailReviewForSeller(access)
      await context.commit('mutationDetailReviewForSeller', response)
    },
    async actionUpdateStatusComment (context, access) {
      const response = await AxiosManageComments.axiosUpdateStatusComment(access)
      await context.commit('mutationUpdateStatusComment', response)
    },
    async actionSetPoints (context, access) {
      const response = await AxiosManageComments.SetPoints(access)
      await context.commit('mutationSetPoints', response)
    },
    async actionSetPointStatus (context, access) {
      const response = await AxiosManageComments.SetPointStatus(access)
      await context.commit('mutationSetPointStatus', response)
    },
    async actionSetCommentStatus (context, access) {
      const response = await AxiosManageComments.SetCommentStatus(access)
      await context.commit('mutationSetCommentStatus', response)
    },
    async actionSetSellerList (context, access) {
      const response = await AxiosManageComments.SetSellerList(access)
      await context.commit('mutationSetSellerList', response)
    },
    async actionSellerDetail (context, access) {
      const response = await AxiosManageComments.SellerDetail(access)
      await context.commit('mutationSellerDetail', response)
    },
    async actionLogList (context, access) {
      const response = await AxiosManageComments.LogList(access)
      await context.commit('mutationLogList', response)
    },
    async actionSeller_manage (context, access) {
      const response = await AxiosManageComments.Seller_manage(access)
      await context.commit('mutationSeller_manage', response)
    }
  }
}

export default NSGModuleManageComments
