import Axiosquotation from './axios_quotation'

const ModuleQuotation = {
  state: {
    stateQuotationBuyer: [],
    stateQuotationDetailBuyer: [],
    stateListAddressUser: [],
    stateCreateQuotation: [],
    stateCancelQuotation: [],
    stateListProductQuotation: [],
    stateListProductQuotationAll: []
  },
  mutations: {
    mutationQuotationBuyer (state, data) {
      state.stateQuotationBuyer = data
    },
    mutationQuotationDetailBuyer (state, data) {
      state.stateQuotationDetailBuyer = data
    },
    mutationListAddressUser (state, data) {
      state.stateListAddressUser = data
    },
    mutationCreateQuotation (state, data) {
      state.stateCreateQuotation = data
    },
    mutationListProductQuotation (state, data) {
      state.stateListProductQuotation = data
    },
    mutationCancelQuotation (state, data) {
      state.stateCancelQuotation = data
    },
    mutationListProductQuotationAll (state, data) {
      state.stateListProductQuotationAll = data
    }
  },
  actions: {
    async actionQuotationBuyer (context, access) {
      const response = await Axiosquotation.axiosQuotationBuyer(access)
      await context.commit('mutationQuotationBuyer', response)
    },
    async actionQuotationDetailBuyer (context, access) {
      const response = await Axiosquotation.axiosQuotationDetailBuyer(access)
      await context.commit('mutationQuotationDetailBuyer', response)
    },
    async actionListAddress (context, access) {
      const response = await Axiosquotation.ListAddressUser(access)
      await context.commit('mutationListAddressUser', response)
    },
    async actionCreateQuotation (context, access) {
      const response = await Axiosquotation.CreateQuotation(access)
      await context.commit('mutationCreateQuotation', response)
    },
    async actionListProductQuotation (context, access) {
      const response = await Axiosquotation.GetListProductQuotation(access)
      await context.commit('mutationListProductQuotation', response)
    },
    async actionCancelQuotation (context, access) {
      const response = await Axiosquotation.GetCancelQuotation(access)
      await context.commit('mutationCancelQuotation', response)
    },
    async actionListProductQuotationAll (context, access) {
      const response = await Axiosquotation.ListProductQuotationAll(access)
      await context.commit('mutationListProductQuotationAll', response)
    }
  }
}

export default ModuleQuotation
