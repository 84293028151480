import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosCreateLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/create_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/list_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEdiStatusLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/edit_status_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/edit_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailLadingPageSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/detail_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/detail_lading_page_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/delete_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosProductLadingPage (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/product_lading_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
