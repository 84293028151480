import AxiosHomepage from '../store_homepage/axios_homepage_api'

const NSGModuleHomepage = {
  state: {
    // product homepage
    stateProductHomepage: [],
    // serach product homepage
    stateSearchProduct: [],
    stateProductDetail: [],
    // Banner Homepage
    stateBannerHomepage: [],
    // Prodcut Category Homepage
    stateProductCategory: [],
    // Product History Purchaser
    stateProductHistory: [],
    // Product Select Option
    stateProductSelectOption: [],
    // list related product
    stateListRelatedProduct: [],
    // Order Buyer
    stateOrderForBuyer: [],
    stateDetailOrderForBuyer: [],
    stateCustomizeLandingPage: [],
    stateCheckStatusShop: [],
    stateUpdateStatusShop: [],
    stateCheckUserAuth: [],
    stateCheckUserTransportTracking: [],
    stateCheckUserNotPaid: [],
    stateCheckUserTrackingAll: [],
    stateCheckPopup: [],
    stateFeedbackReview: [],
    stateFeedbackCommentSatisfaction: [],
    stateCreateCommentFeedback: []
  },
  getters: {
    getterProductDetailNSG: (state) => {
      console.log('statewww', state.stateProductDetail)
      return state.stateProductDetail
    }
  },
  mutations: {
    // product homepage
    mutationsProductHomepage (state, data) {
      state.stateProductHomepage = data
    },
    // serach product homepage
    mutationsSearchProduct (state, data) {
      state.stateSearchProduct = data
    },
    //  Product Detail
    mutationsProductDetail (state, data) {
      state.stateProductDetail = data
      console.log('statesss', state.stateProductDetail)
    },
    // Banner Homepage
    mutationsBannerHomepage (state, data) {
      state.stateBannerHomepage = data
    },
    // Prodcut Category Homepage
    mutationsProductCategory (state, data) {
      state.stateProductCategory = data
    },
    // Product History Purchaser
    mutationsProductHistory (state, data) {
      state.stateProductHistory = data
    },
    // Product Select Option
    mutationsProductSelectOption (state, data) {
      state.stateProductSelectOption = data
    },
    // Product Select Option
    mutationsListRelatedProduct (state, data) {
      state.stateListRelatedProduct = data
    },
    // Order Buyer
    mutationsOrderForBuyer (state, data) {
      state.stateOrderForBuyer = data
    },
    mutationsDetailOrderForBuyer (state, data) {
      state.stateDetailOrderForBuyer = data
    },
    mutationsCustomizeLandingPage (state, data) {
      state.stateCustomizeLandingPage = data
    },
    mutationsCheckStatusShop (state, data) {
      state.stateCheckStatusShop = data
    },
    mutationsUpdateStatusShop (state, data) {
      state.stateUpdateStatusShop = data
    },
    mutationsCheckUserAuth (state, data) {
      state.stateCheckUserAuth = data
    },
    mutationsCheckUserTransportTracking (state, data) {
      state.stateCheckUserTransportTracking = data
    },
    mutationsCheckUserNotPaid (state, data) {
      state.stateCheckUserNotPaid = data
    },
    mutationsCheckUserTrackingAll (state, data) {
      state.stateCheckUserTrackingAll = data
    },
    mutationsCheckPopup (state, data) {
      state.stateCheckPopup = data
    },
    mutationsFeedbackReview (state, data) {
      state.stateFeedbackReview = data
    },
    mutationsFeedbackCommentSatisfaction (state, data) {
      state.stateFeedbackCommentSatisfaction = data
    },
    mutationsCreateCommentFeedback (state, data) {
      state.stateCreateCommentFeedback = data
    }
  },
  actions: {
    // product homepage
    async actionsProductHomepage (context, access) {
      const response = await AxiosHomepage.ProductHomepage(access)
      await context.commit('mutationsProductHomepage', response)
    },
    // serach product homepage
    async actionsSearchProduct (context, access) {
      const response = await AxiosHomepage.SearchProduct(access)
      await context.commit('mutationsSearchProduct', response)
    },
    async actionsNSGProductDetail (context, access) {
      const response = await AxiosHomepage.GetNSGProductDetail(access)
      await context.commit('mutationsProductDetail', response)
    },
    // Banner Homepage
    async actionsBannerHomePage (context, access) {
      const response = await AxiosHomepage.GetBannerHomepage(access)
      await context.commit('mutationsBannerHomepage', response)
    },
    // Prodcut Category Homepage
    async actionsProductCategoey (context, access) {
      const response = await AxiosHomepage.ProductCategoey(access)
      await context.commit('mutationsProductCategory', response)
    },
    // Product History Purchaser
    async actionsProductHistory (context, access) {
      const response = await AxiosHomepage.ProductHistory(access)
      await context.commit('mutationsProductHistory', response)
    },
    async actionsProductSelectOption (context, access) {
      const response = await AxiosHomepage.ProductSelectOption(access)
      await context.commit('mutationsProductSelectOption', response)
    },
    async actionsListRelatedProduct (context, access) {
      const response = await AxiosHomepage.ListRelatedProduct(access)
      await context.commit('mutationsListRelatedProduct', response)
    },
    // Order Buyer
    async actionsOrderForBuyer (context, access) {
      const response = await AxiosHomepage.OrderForBuyer(access)
      await context.commit('mutationsOrderForBuyer', response)
    },
    async actionsDetailOrderForBuyer (context, access) {
      const response = await AxiosHomepage.DetailOrderForBuyer(access)
      await context.commit('mutationsDetailOrderForBuyer', response)
    },
    async actionsCustomizeLandingPage (context, access) {
      console.log('WE RT')
      const response = await AxiosHomepage.CustomizeLandingPage(access)
      await context.commit('mutationsCustomizeLandingPage', response)
    },
    async actionsCheckStatusShop (context, access) {
      // console.log('WE RT')
      const response = await AxiosHomepage.CheckStatusShop(access)
      await context.commit('mutationsCheckStatusShop', response)
    },
    async actionsUpdateStatusShop (context, access) {
      // console.log('WE RT')
      const response = await AxiosHomepage.UpdateStatusShop(access)
      await context.commit('mutationsUpdateStatusShop', response)
    },
    async actionsCheckUserAuth (context) {
      const response = await AxiosHomepage.CheckUserAuth()
      await context.commit('mutationsCheckUserAuth', response)
    },
    async actionsCheckUserTransportTracking (context) {
      const response = await AxiosHomepage.CheckUserTransportTracking()
      await context.commit('mutationsCheckUserTransportTracking', response)
    },
    async actionsCheckUserNotPaid (context) {
      const response = await AxiosHomepage.CheckUserNotPaid()
      await context.commit('mutationsCheckUserNotPaid', response)
    },
    async actionsCheckUserTrackingAll (context, access) {
      const response = await AxiosHomepage.CheckUserTrackingAll(access)
      await context.commit('mutationsCheckUserTrackingAll', response)
    },
    async actionsCheckPopup (context, access) {
      const response = await AxiosHomepage.CheckPopup(access)
      await context.commit('mutationsCheckPopup', response)
    },
    async actionsFeedbackReview (context, access) {
      const response = await AxiosHomepage.FeedbackReview(access)
      await context.commit('mutationsFeedbackReview', response)
    },
    async actionsFeedbackCommentSatisfaction (context, access) {
      const response = await AxiosHomepage.FeedbackCommentSatisfaction(access)
      await context.commit('mutationsFeedbackCommentSatisfaction', response)
    },
    async actionsCreateCommentFeedback (context, access) {
      const response = await AxiosHomepage.CreateCommentFeedback(access)
      await context.commit('mutationsCreateCommentFeedback', response)
    }
  }
}

export default NSGModuleHomepage
