import AxiosManageVoucher from './axios_manage_voucher'

const ModuleManageVoucher = {
  state: {
    stateCreateVoucher: [],
    stateEditVoucher: [],
    stateListVoucherAll: [],
    stateListVoucher: [],
    stateListVoucherUse: [],
    stateDetailVoucher: [],
    stateDeleteMemberClass: [],
    stateGetVoucherUser: [],
    stateDeleteVoucher: [],
    stateUseVoucher: [],
    stateShowStatusVoucher: [],
    stateSearchVoucher: [], // keep voucher with search
    stateUseVoucherNewVersion: [],
    stateVoucherHistory: [],
    stateVoucherListCustomer: [],
    stateHistoryVoucherBuyer: []
  },
  mutations: {
    mutationsCreateVoucher (state, data) {
      state.stateCreateVoucher = data
    },
    mutationEditVoucher (state, data) {
      state.stateEditVoucher = data
    },
    mutationListVoucherAll (state, data) {
      state.stateListVoucherAll = data
    },
    mutationListVoucher (state, data) {
      state.stateListVoucher = data
    },
    mutationListVoucherUse (state, data) {
      state.stateListVoucherUse = data
    },
    mutationDetailVoucher (state, data) {
      state.stateDetailVoucher = data
    },
    mutationDeleteMemberClass (state, data) {
      state.stateDeleteMemberClass = data
    },
    mutationGetVoucherUser (state, data) {
      state.stateGetVoucherUser = data
    },
    mutationDeleteVoucher (state, data) {
      state.stateDeleteVoucher = data
    },
    mutationUseVoucher (state, data) {
      state.stateUseVoucher = data
    },
    mutationShowStatusVoucher (state, data) {
      state.stateShowStatusVoucher = data
    },
    mutationSearchVoucher (state, data) {
      // mutation ของการ search code
      state.stateSearchVoucher = data
    },
    mutationUseVoucherNewVersion (state, data) {
      // mutation ของการใช้ Voucher เวอร์ชันใหม่ของระบบ(ปรับใหม่)
      state.stateUseVoucherNewVersion = data
    },
    mutationVoucherHistory (state, data) {
      // ประวัติการใช้ voucher
      state.stateVoucherHistory = data
    },
    mutationVoucherListCustomer (state, data) {
      state.stateVoucherListCustomer = data
    },
    mutationHistoryVoucherBuyer (state, data) {
      state.stateHistoryVoucherBuyer = data
    }
  },
  actions: {
    async actionsCreateVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosCreateVoucher(access)
      await context.commit('mutationsCreateVoucher', response)
    },
    async actionsEditVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosEditVoucher(access)
      await context.commit('mutationEditVoucher', response)
    },
    async actionListVoucherAll (context, access) {
      const response = await AxiosManageVoucher.axiosListVoucherAll(access)
      await context.commit('mutationListVoucherAll', response)
    },
    async actionListVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosListVoucher(access)
      await context.commit('mutationListVoucherUse', response)
    },
    async actionDetailVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosDetailVoucher(access)
      await context.commit('mutationDetailVoucher', response)
    },
    async actionListVoucherHome (context, access) {
      const response = await AxiosManageVoucher.axiosListVoucherHome(access)
      await context.commit('mutationListVoucher', response)
    },
    async actionDetailVoucherHome (context, access) {
      const response = await AxiosManageVoucher.axiosDetailVoucherHome(access)
      await context.commit('mutationDetailVoucher', response)
    },
    async actionDeleteMemberClass (context, access) {
      const response = await AxiosManageVoucher.axiosDeleteMemberClass(access)
      await context.commit('mutationDeleteMemberClass', response)
    },
    async actionGetVoucherUser (context, access) {
      const response = await AxiosManageVoucher.axiosGetVoucherUser(access)
      await context.commit('mutationGetVoucherUser', response)
    },
    async actionDeleteVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosDeleteVoucher(access)
      await context.commit('mutationDeleteVoucher', response)
    },
    async actionUseVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosUseVoucher(access)
      await context.commit('mutationUseVoucher', response)
    },
    async actionShowStatusVoucher (context, access) {
      const response = await AxiosManageVoucher.axiosShowStatusVoucher(access)
      await context.commit('mutationShowStatusVoucher', response)
    },
    async actionSearchVoucher (context, access) {
      // search in shop
      const response = await AxiosManageVoucher.axiosSearchVoucher(access)
      await context.commit('mutationSearchVoucher', response)
    },
    async actionUseVoucherNewVersion (context, access) {
      const response = await AxiosManageVoucher.axiosUseVoucherNew(access)
      await context.commit('mutationUseVoucherNewVersion', response)
    },
    async actionVoucherHistory (context, access) {
      const response = await AxiosManageVoucher.axiosVoucherHistory(access)
      await context.commit('mutationVoucherHistory', response)
    },
    async actionVoucherListCustomer (context, access) {
      const response = await AxiosManageVoucher.axiosVoucherListCustomer(access)
      await context.commit('mutationVoucherListCustomer', response)
    },
    async actionHistoryVoucherBuyer (context, access) {
      const response = await AxiosManageVoucher.axiosHistoryVoucherBuyer(access)
      await context.commit('mutationHistoryVoucherBuyer', response)
    }
  }
}

export default ModuleManageVoucher
