import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosCreateVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/create_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/edit_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteVoucher (val) {
    const data = val
    const auth = await GetToken()
    console.log('Api', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/delete_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListVoucherAll (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/list_voucher_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_voucher_can_buy`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailVoucher (val) {
    console.log('Detail voucher')
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/detail_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListVoucherHome (val) {
    // ใช้กับหน้าหลัก Home
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/list_voucher_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailVoucherHome (val) {
    console.log('Detail voucher')
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/detail_voucher_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteMemberClass (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/delete_member_class`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetVoucherUser (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/voucher_user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUseVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/manage_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosShowStatusVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/update_status_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSearchVoucher (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/search_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUseVoucherNew (val) {
    // api ที่ปรับใหม่ในการใช้ Voucher
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/use_voucher`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosVoucherHistory (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/history_voucher_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosVoucherListCustomer () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}/list_all_employee`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosHistoryVoucherBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}voucher/history_voucher_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
