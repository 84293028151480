import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}
export default {
  async GetQuotationlistSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetQuotationDetailSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/detail_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AcceptQuotationSeller (val) {
    const data = val
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    // const auth = await GetToken()
    const auth = {
      headers: {
        Authorization: `Bearer ${oneData.user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/accept_quotation`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelQuotationSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/cancel_quotation`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ExportQuotationSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}quotation/export_quotation`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
