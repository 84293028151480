import AxiosSaleMan from './axios_sale_man'

const NSGSaleMan = {
  state: {
    stateSaleManDetail: [],
    stateListAllSaleMan: [],
    stateListCustomerForSaleMan: [],
    stateListOrderSaleMan: []

  },
  mutations: {
    mutationSaleManDetail (state, data) {
      state.stateSaleManDetail = data
    },
    mutationListAllSaleMan (state, data) {
      state.stateListAllSaleMan = data
    },
    mutationsListCustomerForSaleMan (state, data) {
      state.stateListCustomerForSaleMan = data
    },
    mutationsListOrderSaleMan (state, data) {
      state.stateListOrderSaleMan = data
    }
  },
  actions: {
    async actionsSaleManDetail (context, access) {
      const response = await AxiosSaleMan.SaleManDetail(access)
      await context.commit('mutationSaleManDetail', response)
    },
    async actionsListAllSaleMan (context, access) {
      const response = await AxiosSaleMan.ListAllSaleMan(access)
      await context.commit('mutationListAllSaleMan', response)
    },
    async actionsListCustomerForSaleMan (context) {
      const response = await AxiosSaleMan.ListCustomerForSaleMan()
      await context.commit('mutationsListCustomerForSaleMan', response)
    },
    async actionsListOrderSaleMan (context, access) {
      const response = await AxiosSaleMan.ListOrderSaleMan(access)
      await context.commit('mutationsListOrderSaleMan', response)
    }
  }
}

export default NSGSaleMan
