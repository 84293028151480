export function msgErr (msg, data) {
  if (msg === 'This user is unauthorized.') {
    return ('ไม่สามารถยืนยันตัวผู้ใช้งานได้', 'warning')
  } else if (msg === 'Parameter missing') {
    return ['ข้ออมูลบางส่วนขาดหาย', 'warning']
  } else if (msg === 'news_id not found') {
    return ['ไม่พบไอดีของข่าวที่เลือก', 'warning']
  } else if (msg === 'Parameter missing [news_id]') {
    return ['ข้ออมูลไอดีขาดหาย', 'warning']
  } else if (msg === 'coupon collect exceed limit') {
    return ['คูปองเคยเก็บแล้้ว', 'warning']
  } else if (msg === 'This token is expired') {
    return ['รหัสล็อกอินหมดอายุ กรุณาล็อกอินใหม่', 'warning']
  } else if (msg === 'morethan500') {
    return [`ข้อมูลใน sheet ${data} มีมากกว่า 500 แถว`, 'warning']
    // manage pproduct
  } else if (msg === 'Parameter is missing. [sku]') {
    return ['ข้อมูล SKU ขาดหาย', 'warning']
  } else if (msg === 'Product name cannot be null or empty.') {
    return ['ชื่อสินค้าไม่สามารถเป็นค่าว่างได้', 'warning']
  } else if (msg === 'product_sku already exist') {
    return ['SKU นี้ถูกใช้แล้ว', 'warning']
  } else if (msg === 'category not found') {
    return ['ไม่พบหมวดหมู่นี้', 'warning']
  } else if (msg === 'Not found this message status.') {
    return ['ไม่พบสถานะสินค้านี้', 'warning']
  } else if (msg === 'Some new sku in attribute data was duplicate.') {
    return ['SKU ของรายละเอียดสินค้าซ้ำกัน', 'warning']
  } else if (msg === 'This attribute sku is already exist in your shop') {
    return ['SKU ของรายละเอียดสินค้ามีในระบบแล้ว', 'warning']
  } else if (msg === 'Not found this product') {
    return ['ไม่พบสินค้านี้ในระบบ', 'warning']
  } else if (msg === 'Parameter is missing.[sku]') {
    return ['ข้อมูล SKU ขาดหาย', 'warning']
  } else if (msg === 'This sku is already exist in your shop') {
    return ['SKU นี้ถูกใช้ในระบบแล้ว', 'warning']
  } else if (msg === 'Please select category.') {
    return ['กรุณาเลือกประเภทสินค้า', 'warning']
  } else if (msg === 'Category ID not found.') {
    return ['ไม่พบประเภทของสินค้าที่เลือกในระบบ', 'warning']
    // manage member
  } else if (msg === 'This User Already Have Member Class') {
    return ['ระดับสมาชิกนี้มีสมาชิกอยู่', 'warning']
  } else if (msg === 'Can not change status.') {
    return ['ไม่สามารถเปลี่ยนแปลงผลคำขอยกเลิกนี้ได้', 'warning']
    // flash sale
  } else if (msg === 'Can not delete this flashsale (แฟลชเซลล์นี้เริ่มต้น/สิ้นสุดแล้ว ไม่สามารถลบได้)') {
    return ['แฟลชเซลล์นี้เริ่มต้น/สิ้นสุดแล้ว ไม่สามารถลบได้', 'warning']
  } else if (msg === 'Flashsale data not found') {
    return ['ไม่พบข้อมูลแฟลชเซลล์นี้', 'warning']
  } else if (msg === 'Flashsale product data not found') {
    return ['ไม่พบข้อมูลสินค้าแฟลชเซลล์นี้', 'warning']
  } else if (msg === 'Status invalid') {
    return ['สถานะไม่ถูกต้อง', 'warning']
    // manage position
  } else if (msg === 'position not found') {
    return ['ไม่พบข้อมูลตำแหน่ง', 'warning']
  } else {
    return [msg, 'error']
  }
}
export function statusErr (number) {
  console.log(number)
  if (number === 500) {
    return ['ระบบมีปัญหา กรุณาลองใหม่อีกครั้งใน 1-2 ชม.', 'warning']
  } else if (number === 400) {
    return ['ระบบมีปัญหา กรุณาลองใหม่อีกครั้งใน 1-2 ชม.', 'warning']
  } else {
    return [number, 'error']
  }
}
