import AxiosHomepage from '../store_flash_sale/axios_flashsale_api'

const NSGModuleFlashSale = {
  state: {
    //  NSG Flash Sale
    stateFlashSale: []
  },
  mutations: {
    //  NSG Flash Sale
    mutationsFlashSale (state, data) {
      state.stateFlashSale = data
    }
  },
  actions: {
    //  NSG Flash Sale
    async actionsFlashSale (context, access) {
      const response = await AxiosHomepage.FlashSaleList(access)
      await context.commit('mutationsFlashSale', response)
    }
  }
}

export default NSGModuleFlashSale
