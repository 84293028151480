import AxiosPromotion from './axios_promotion'

const ModulePromotion = {
  state: {
    stateListPromotion: [],
    stateListPromotionNoCam: [],
    stateCreatePromotion: [],
    stateEditPromotion: [],
    stateDetailPromotion: [],
    stateDeletePromotion: [],
    stateCollectPromotion: [],
    stateGetCenterPoint: [],
    stateEditCenterPoint: [],
    stateCreateNGSImage: []
  },
  mutations: {
    mutationListPromotion (state, data) {
      state.stateListPromotion = data
    },
    mutationListPromotionNoCam (state, data) {
      state.stateListPromotionNoCam = data
    },
    mutationCreatePromotion (state, data) {
      state.stateCreatePromotion = data
    },
    mutationEditPromotion (state, data) {
      state.stateEditPromotion = data
    },
    mutationDetailPromotion (state, data) {
      state.stateDetailPromotion = data
    },
    mutationDeletePromotion (state, data) {
      state.stateDeletePromotion = data
    },
    mutationCollectPromotion (state, data) {
      state.stateCollectPromotion = data
    },
    mutationGetCenterPoint (state, data) {
      state.stateGetCenterPoint = data
    },
    mutationEditCenterPoint (state, data) {
      state.stateEditCenterPoint = data
    },
    mutationCreateNGSImage (state, data) {
      state.stateCreateNGSImage = data
    }
  },
  actions: {
    async actionListPromotion (context, access) {
      const response = await AxiosPromotion.axiosListPromotion(access)
      await context.commit('mutationListPromotion', response)
    },
    async actionListPromotionNoCam (context, access) {
      const response = await AxiosPromotion.axiosListPromotionNoCam(access)
      await context.commit('mutationListPromotionNoCam', response)
    },
    async actionCreatePromotion (context, access) {
      const response = await AxiosPromotion.axiosCreatePromotion(access)
      await context.commit('mutationCreatePromotion', response)
    },
    async actionEditPromotion (context, access) {
      const response = await AxiosPromotion.axiosEditPromotion(access)
      await context.commit('mutationEditPromotion', response)
    },
    async actionDetailPromotion (context, access) {
      const response = await AxiosPromotion.axiosDetailPromotion(access)
      await context.commit('mutationDetailPromotion', response)
    },
    async actionDeletePromotion (context, access) {
      const response = await AxiosPromotion.axiosDeletePromotion(access)
      await context.commit('mutationDeletePromotion', response)
    },
    async actionCollectPromotion (context, access) {
      const response = await AxiosPromotion.axiosCollectPromotion(access)
      await context.commit('mutationCollectPromotion', response)
    },
    async actionGetCenterPoint (context, access) {
      const response = await AxiosPromotion.axiosGetCenterPoint(access)
      await context.commit('mutationGetCenterPoint', response)
    },
    async actionEditCenterPoint (context, access) {
      const response = await AxiosPromotion.axiosEditCenterPoint(access)
      await context.commit('mutationEditCenterPoint', response)
    },
    async actionCreateNGSImage (context, access) {
      const response = await AxiosPromotion.axiosCreateNGSImage(access)
      await context.commit('mutationCreateNGSImage', response)
    }
  }
}

export default ModulePromotion
