import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosDetailUserSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/detail_user_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailPosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/detail_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreatePosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/create_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditPosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/edit_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListAllStaff (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listAllStaff`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axioslistPosition2 (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listPosition`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosjobTransfer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/jobTransfer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosShowStatusPromotion (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/show_status_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosPermissionListAllStaff (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listAllStaff`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosPermissionListPosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listPosition`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosPermissionListAdmin (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listAdmin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axioslistPosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/list_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axioslistSuperAdmin (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listSuperAdmin`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailSuperAdmin (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/detail_super_admin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeletePosition (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/delete_position`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListStaff (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listStaff`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSetUserPosition (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/set_user_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListAdmin (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/list_admin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteUserSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/delete_user_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosTransferlistAdminStaff (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listAdmin_Staff`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosChangeSuperAdmin (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}position/change_super_admin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSearchNotStaff (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}permission/listNotStaff`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSettingEmployee (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}shop/setting_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailSettingEmployee (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}shop/detail_setting_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
