import AxiosManageReturn from './axios_manage_return'
const NSGModuleManageReturn = {
  state: {
    stateListRefundSeller: [],
    stateDetailRefundSeller: [],
    stateApproveRefundSeller: [],

    // Buyer Part
    stateListRefundBuyer: [],
    stateDetailRefundBuyer: [],
    stateSendRefund: []
  },
  mutations: {
    mutationListRefundSeller (state, data) {
      state.stateListRefundSeller = data
    },
    mutationDetailRefundSeller (state, data) {
      state.stateDetailRefundSeller = data
    },
    mutationApproveRefundSeller (state, data) {
      state.stateApproveRefundSeller = data
    },

    // Buyer Part
    mutationListRefundBuyer (state, data) {
      state.stateListRefundBuyer = data
    },
    mutationDetailRefundBuyer (state, data) {
      state.stateDetailRefundBuyer = data
    },
    mutationSendRefund (state, data) {
      state.stateSendRefund = data
    }
  },
  actions: {
    async actionListRefundSeller (context, access) {
      const response = await AxiosManageReturn.axiosListRefundSeller(access)
      await context.commit('mutationListRefundSeller', response)
    },
    async actionDetailRefundSeller (context, access) {
      const response = await AxiosManageReturn.axiosDetailRefundSeller(access)
      await context.commit('mutationDetailRefundSeller', response)
    },
    async actionApproveRefundSeller (context, access) {
      const response = await AxiosManageReturn.axiosApproveRefundSeller(access)
      await context.commit('mutationApproveRefundSeller', response)
    },

    // Buyer Part
    async actionListRefundBuyer (context, access) {
      const response = await AxiosManageReturn.axiosListRefundBuyer(access)
      await context.commit('mutationListRefundBuyer', response)
    },
    async actionDetailRefundBuyer (context, access) {
      const response = await AxiosManageReturn.axiosDetailRefundBuyer(access)
      await context.commit('mutationDetailRefundBuyer', response)
    },
    async actionSendRefund (context, access) {
      const response = await AxiosManageReturn.axiosSendRefund(access)
      await context.commit('mutationSendRefund', response)
    }
  }
}

export default NSGModuleManageReturn
