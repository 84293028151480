import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosGetProductAll (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_product_list_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosGetProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_product_list`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}product/add_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosEditProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}product/edit_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_product_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeletelProduct (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}news_activity/delete_news`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListProductByCat (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/list_product_by_cate`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDefaultUserAddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}user/update_default_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUpdateStatusShowSold (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}product/update_status_show_sold_for_product`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDeleteProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}product/delete_product`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosWholesaleStatus (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}/wholesale_status`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosbestSellerManage (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}best_seller/manage `, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosbestSellerProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}best_seller/seller_list `, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
