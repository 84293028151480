import AxiosUserManual from './axios_user_manual'

const NSGUserManual = {
  state: {
    stateListUserManual: []
  },
  mutations: {
    mutationListUserManual (state, data) {
      state.stateListUserManual = data
    }
  },
  actions: {
    async actionListUserManual (context, access) {
      const response = await AxiosUserManual.ListUserManual(access)
      await context.commit('mutationListUserManual', response)
    }
  }
}

export default NSGUserManual
