<template>
  <!-- <v-app> -->
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
      :style="MobileSize ? 'z-index: 16000004' : ''"
      persistent
    >
      <v-card style="overflow-x: hidden;" min-height="600px" :class="MobileSize ? 'py-4 px-2' : 'py-4 px-6' ">
        <v-row justify="center" class="mb-4">
          <v-card-title>
            <v-col cols="12" align="center">
              <span style="font-weight: 700;" :style="MobileSize ? 'font-size: 14pt;' : 'font-size: 16pt;' ">นโยบายความเป็นส่วนตัว</span><br>
            </v-col>
          </v-card-title>
          <v-card-text>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">บริษัท วันรัต (หน่ำเซียน) จำกัด ขอแจ้งให้ทราบถึงนโยบายของเว็บไซต์ของบริษัท ในการรักษาความลับสำหรับข้อมูลของลูกค้า หรือบุคคลใดที่ได้เข้ามาเยี่ยมชมเว็บไซต์ของบริษัท ดังต่อไปนี้</p>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'">การติดต่อสื่อสารกับลูกค้า</p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">ทางเว็บไซต์
              <a href="https://internaldevsitepanit.one.th/NSG/" v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</a>
              <a href="https://nsg.shopping/" v-else>https://nsg.shopping/</a>
              จะติดต่อกับท่านผ่านทางอีเมลและการโทรศัพท์เท่านั้น โดยจะพิจารณาติดต่อผ่านทางอีเมลก่อน โดยในส่วนหัวเรื่องของอีเมลจะทำการแจ้งวัตถุประสงค์ในการติดต่อทุกครั้ง <br /> หากท่านต้องการติดต่อกลับมาไม่ว่าจะเป็นเรื่องสินค้าหรืออื่น ๆ ท่านสามารถติดต่อได้ทางหน้า
              <a href="https://internaldevsitepanit.one.th/NSG/Contact" v-if="web === 'internal'">ติดต่อร้านค้า"</a>
              <a href="https://nsg.shopping/Contact" v-else>"ติดต่อร้านค้า"</a>
              หรือการอีเมลหรือโทรศัพท์ ตามข้อมูลที่แจ้งในทุกหน้าของเว็บไซต์ ซึ่งท่านจะได้รับการติดต่อกลับภายในวันและเวลาทำการ</p>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'">การเข้าเยี่ยมชมเว็บไซต์
              <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
              <span v-else>https://nsg.shopping/</span>
            </p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">ท่านสามารถเข้าเยี่ยมชมเว็บไซต์ของ
              <a href="https://internaldevsitepanit.one.th/NSG/" v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</a>
              <a href="https://nsg.shopping/" v-else>https://nsg.shopping/</a>
              ได้ เพื่อการค้นหาสินค้าและบริการต่างๆ ของบริษัท  โดยที่ท่านไม่ต้องแจ้งข้อมูลเกี่ยวกับตัวท่านให้แก่ทางเว็บไซต์ หากท่านต้องการรับข่าวสารจากบริษัท ท่านสามารถลงทะเบียนได้ตามที่บริษัท กำหนด โดยที่ท่านสามารถยกเลิกการรับข่าวสารได้โดยส่งอีเมลมาที่ nsgshopping@namsiang.com <br />เว็บไซต์ของบริษัท ได้ดำเนินการปรับปรุงเว็บไซต์อย่างต่อเนื่อง บริษัท อาจใช้อุปกรณ์ ซอฟต์แวร์ เพื่อการเก็บรวบรวมข้อมูลในด้านพฤติกรรมการสืบค้นข้อมูล ของผู้ที่เข้ามาเยี่ยมชมเว็บไซต์ของบริษัท ซึ่งบริษัท จะนำข้อมูลดังกล่าวไปใช้ในวัตถุประสงค์เพื่อการปรับปรุงเว็บไซต์ให้เข้าถึงกลุ่มเป้าหมายได้อย่างมีประสิทธิภาพ ซึ่งข้อมูลที่มีการรวบรวมจะเป็นข้อมูล</p>
            <ol style="margin-left: 20px;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <li>วัน และเวลาในการเข้าเยี่ยมชมเว็บไซต์</li>
              <li>การดูหน้าเว็บเพจต่างๆ</li>
              <li>เวลาที่ใช้ในการเยี่ยมชม</li>
              <li>ประเภทของการสืบค้น</li>
              <li>ผู้ให้บริการอินเทอร์เน็ต</li>
              <li>การเข้าเยี่ยมชมเว็บไซต์ต่างๆ ทั้งก่อนและหลังการเข้าชมเว็บไซต์ของบริษัท</li>
            </ol>
            <p :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
              <span v-else>https://nsg.shopping/</span>
              อาจทำการตรวจสอบถึงพฤติกรรมการสืบค้นข้อมูล ของลูกค้าที่เป็นสมาชิกของเว็บไซต์ และใช้บริการต่างๆ โดยส่วนหนึ่งของกระบวนการรักษาความปลอดภัยของเว็บไซต์นั้น บริษัท อาจใช้ระบบ "คุกกี้" ( cookie) ในการตรวจสอบความถูกต้องแท้จริงของผู้เข้าเยี่ยมชมเว็บไซต์ ระบบ "คุกกี้" นั้น คือข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์ของผู้เข้าเยี่ยมชมเว็บไซต์ ในขณะที่ผู้เข้าเยี่ยมชมเว็บไซต์นั้นกำลังเข้าเยี่ยมชมเว็บไซต์ของบริษัท โดย "คุกกี้" นั้นจะได้รับการติดตั้งโดยเว็บไซต์ของบริษัท ซึ่งจะเก็บข้อมูลของท่านดังต่อไปนี้</p>
            <ul style="margin-left: 20px;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <li>หมายเลขไอพี (IP Address)</li>
              <li>ชนิดของ Browser ที่ใช้ในการเข้าถึง</li>
              <li>Web Page ที่เข้าเยี่ยมชม</li>
              <li>เวลาที่เยี่ยมชม (Access Time)</li>
              <li>เว็บไซต์ที่อ้างถึงเว็บของบริษัท (Referring Website)</li>
            </ul>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'">การรวบรวมและการเก็บรักษาข้อมูลส่วนบุคคล </p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">ข้อมูลที่เกี่ยวข้องกับตัวลูกค้าของเว็บไซต์
              <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
              <span v-else>https://nsg.shopping/</span>
              นั้น จะถูกนำไปใช้เพื่อวัตถุประสงค์ในการดำเนินงานของบริษัท และถูกต้องตามกฎหมายเท่านั้น อันประกอบไปด้วย การเพิ่มประสิทธิภาพให้บริการ และนำไปใช้เพื่อการออกแบบสินค้าหรือบริการต่างๆ และข้อเสนอพิเศษต่างๆ ที่ดีขึ้นกว่าเดิม ซึ่งเป็นไปตามความต้องการของลูกค้า ข้อมูลของลูกค้าจะไม่ถูกนำไปใช้ เก็บรวบรวม หรือรักษาไว้โดยไม่ตรงตามวัตถุประสงค์ <br />อย่างไรก็ตามในกรณีที่บริษัท ต้องการเก็บข้อมูลโดยที่ไม่มีวัตถุประสงค์ในการดำเนินการดังกล่าวข้างต้น บริษัท จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านโดยการขอข้อมูลจากท่านโดยตรง หากท่านเลือกที่จะให้ข้อมูลส่วนบุคคล ดังต่อไปนี้ ผ่านกระบวนการสมัคร</p>
            <ul style="margin-left: 20px;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <li>ชื่อ-นามสกุล</li>
              <li>ที่อยู่</li>
              <li>Email</li>
              <li>เบอร์โทรศัพท์ </li>
            </ul>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">ให้กับเว็บไซต์บริษัท ตลอดจนกิจกรรมทางธุรกิจหรือการดำเนินธุรกรรมใดๆ ของท่านแก่เว็บไซต์บริษัท แล้ว บริษัท จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับ และจะปฏิบัติตามที่กฎหมายกำหนด<br/>ข้อมูลเกี่ยวกับลูกค้าของบริษัท จะถูกเก็บรวบรวม และรักษาไว้ในระหว่างระยะเวลาที่บริษัท และท่านยังคงมีการติอต่อทางธุรกิจต่อกัน นอกจากนี้ เมื่อท่านได้ส่ง e-mail มายังบริษัท บริษัท จะเก็บเนื้อหาใน e-mail ที่อยู่ของ e-mail และการโต้ตอบ e-mail ดังกล่าวไว้ เพื่อตอบข้อสงสัยให้แก่ท่าน หรือเพื่อความจำเป็นในการติดตามผล และเพื่อตรวจสอบถึงการโต้ตอบระหว่างลูกค้าและเจ้าหน้าที่ของบริษัท ในส่วนที่ท่านมีข้อสงสัยนั้นว่าได้รับการแก้ไขหรือไม่</p>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'">การเปิดเผยข้อมูลส่วนบุคคล</p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">เว็บไซต์
              <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
              <span v-else>https://nsg.shopping/</span>
              จะไม่ส่งต่อหรือขายข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกอื่น เพื่อวัตถุประสงค์ในการนำไปใช้โดยไม่ได้รับอนุญาติ นอกจากนี้ บริษัท จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่องค์กรอื่นใด เว้นแต่</p>
            <ol style="margin-left: 0px;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <li>ท่านได้ร้องขอและให้อำนาจแก่เว็บไซต์บริษัท ในการดำเนินการ หรือ </li>
              <li>ข้อมูลที่ส่งต่อนั้นเป็นไปเพื่อช่วยให้การเริ่มต้นธุรกรรมของท่านสำเร็จลุล่วงลง หรือ </li>
              <li>การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาต หรือเป็นไปตามที่กฎหมายต้องการ ตัวอย่างเช่น เว็บไซต์
                <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
                <span v-else>https://nsg.shopping/</span>
                อาจได้รับการร้องขอให้ทำการเปิดเผยข้อมูลเกี่ยวกับลูกค้าของบริษัท ตามคำสั่งศาล หรือตามหมายศาล หรือหน่วยงานอื่นๆ ที่มีอำนาจตามกฎหมาย ในการณ์นี้ทางเว็บไซต์
                <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
                <span v-else>https://nsg.shopping/</span>
                จะทำการติดต่อแจ้งท่านล่วงหน้าเสมอ <br />เว็บไซต์
                <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
                <span v-else>https://nsg.shopping/</span>
                อาจทำความตกลงไว้กับผู้ให้บริการภายนอก (Third Party) ในการที่จะพัฒนาและดูแลรักษาระบบพาณิชย์อิเล็กทรอนิกส์ (e-commerce) ของบริษัท และการจัดสรรทรัพยากรหรือบริการในนามของบริษัท ซึ่งบุคคลภายนอกที่เข้ามาดำเนินงานให้กับบริษัท หรือเข้ามาดำเนินการในนามของบริษัท นั้น จะต้องตกลงที่จะรักษาข้อมูลลูกค้าของเว็บไซต์
                <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
                <span v-else>https://nsg.shopping/</span>
                ไว้เป็นความลับด้วยเช่นกัน อีกทั้งยังต้องผูกพันต่อกฎหมายที่มีผลใช้บังคับด้วย เมื่อใดก็ตามที่เว็บไซต์
                <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
                <span v-else>https://nsg.shopping/</span>
                ได้ว่าจ้างองค์กรอื่นใด เข้ามาให้การสนับสนุนการบริการของบริษัท องค์กรเหล่านั้นจะต้องปฏิบัติตาม มาตรฐานการรักษาข้อมูลเป็นความลับของบริษัท ด้วยเช่นกัน</li>
            </ol>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'">การรักษาความถูกต้องของข้อมูล</p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">เว็บไซต์
              <span v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</span>
              <span v-else>https://nsg.shopping/</span>
              มีกระบวนการในการสร้างความมั่นใจว่า ข้อมูลของท่านเป็นข้อมูลที่ถูกต้อง เป็นปัจจุบัน และมีความสมบูรณ์ตามมาตรฐานทางธุรกิจ นอกจากนี้ บริษัท ยังมีกระบวนการในการแก้ไขข้อมูลที่ผิดพลาด ตามการร้องขอของท่านได้อีกด้วย โดยท่านสามารถแก้ไขข้อมูลที่่ผิดพลาดด้วยวิธีการดังนี้ </p>
            <ol style="margin-left: 0px;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'">
              <li>Log in เข้า
                <a href="https://internaldevsitepanit.one.th/NSG/" v-if="web === 'internal'">https://internaldevsitepanit.one.th/NSG/</a>
                <a href="https://nsg.shopping/" v-else>https://nsg.shopping/</a>
              </li>
              <li>เลือก กด "ข้อมูลส่วนตัว" แท็บซ้าย และคลิก แก้ไขข้อมูล</li>
            </ol>

            <p style="font-weight: bold; padding: 15px 0;" :style="MobileSize ? 'font-size: 14pt !important;' : 'font-size: 16pt !important;'"></p>
            <p style="!important;" :style="MobileSize ? 'font-size: 12pt' : 'font-size: 14pt'"></p>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn dense dark outlined color="#2A70C3" class="pl-7 pr-7" @click="Consent('N')">
              ไม่ยินยอม
          </v-btn>
          <v-btn dense color="#2A70C3" class="ml-4 mt-1 pl-8 pr-8 white--text" @click="Consent('Y')">
              ยินยอม
          </v-btn>
        </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
</template>

<script>
// import axios from 'axios'
// import { Decode } from '@/services'
import { statusErr } from '@/enum/GetError'
export default {
  data () {
    return {
      one_id: '',
      dialog: false,
      data: '',
      checkbox: false,
      web: ''
    }
  },
  created () {
    // console.log(window.location.host)
    this.web = window.location.host.search('shopping') !== -1 ? 'devsite' : 'internal'
    // console.log(this.web)
  },
  computed: {
    MobileSize () {
      const { xs } = this.$vuetify.breakpoint
      return !!xs
    },
    IpadProSize () {
      const { md } = this.$vuetify.breakpoint
      return !!md
    },
    IpadSize () {
      const { sm } = this.$vuetify.breakpoint
      return !!sm
    },
    desktopSize () {
      const { xl } = this.$vuetify.breakpoint
      return !!xl
    }
  },
  methods: {
    open () {
      console.log('In Dialog PDPA')
      this.dialog = true
    },
    async Consent (status) {
      var data = { status_pdpa: status }
      await this.$store.dispatch('actionAcceptPDPA', data)
      var res = await this.$store.state.NSGModuleAcceptPDPA.stateAcceptPDPA
      console.log(res)
      if (res.result === 'SUCCESS') {
        if (res.message === 'This User Accept PDPA') {
          console.log('This User Accept PDPA')
          this.dialog = false
          this.$EventBus.$emit('getUserDetail')
        } else {
          this.$EventBus.$emit('Logout')
        }
      } else {
        if (res.result === 'FAILED') {
          var [msg, iconMsg] = statusErr(res.code)
          this.$swal.fire({ icon: iconMsg, text: msg, showConfirmButton: false, timer: 2000 })
        } else {
          [msg, iconMsg] = statusErr(res.code)
          this.$swal.fire({ icon: iconMsg, text: msg, showConfirmButton: false, timer: 2000 })
        }
      }
    }
  }
}
</script>
