import AxiosManageProduct from './axios_manage_product'

const ModuleManageProduct = {
  state: {
    stateGetProductAll: [],
    stateGetProduct: [],
    stateCreateProduct: [],
    stateEditProduct: [],
    stateDetailProduct: [],
    stateDeletelProduct: [],
    stateListProductByCat: [],
    stateDefaultUserAddress: [],
    stateUpdateStatusShowSold: [],
    stateDeleteProduct: [],
    stateWholesaleStatus: [],
    stateBestSellerProduct: [],
    stateBestSellerManage: []
  },
  mutations: {
    mutationGetProductAll (state, data) {
      state.stateGetProductAll = data
    },
    mutationGetProduct (state, data) {
      state.stateGetProduct = data
    },
    mutationCreateProduct (state, data) {
      state.stateCreateProduct = data
    },
    mutationEditProduct (state, data) {
      state.stateEditProduct = data
    },
    mutationDetailProduct (state, data) {
      state.stateDetailProduct = data
    },
    mutationDeletelProduct (state, data) {
      state.stateDeletelProduct = data
    },
    mutationListProductByCat (state, data) {
      state.stateListProductByCat = data
    },
    mutationDefaultUserAddress (state, data) {
      state.stateDefaultUserAddress = data
    },
    mutationUpdateStatusShowSold (state, data) {
      state.stateUpdateStatusShowSold = data
    },
    mutationDeleteProduct (state, data) {
      state.stateDeleteProduct = data
    },
    mutationWholesaleStatus (state, data) {
      state.stateWholesaleStatus = data
    },
    mutationBestSellerProduct (state, data) {
      state.stateBestSellerProduct = data
    },
    mutationBestSellerManage (state, data) {
      state.stateBestSellerManage = data
    }
  },
  actions: {
    async actionGetProductAll (context, access) {
      const response = await AxiosManageProduct.axiosGetProductAll(access)
      await context.commit('mutationGetProductAll', response)
    },
    async actionGetProduct (context, access) {
      const response = await AxiosManageProduct.axiosGetProduct(access)
      await context.commit('mutationGetProduct', response)
    },
    async actionCreateProduct (context, access) {
      const response = await AxiosManageProduct.axiosCreateProduct(access)
      await context.commit('mutationCreateProduct', response)
    },
    async actionEditProduct (context, access) {
      const response = await AxiosManageProduct.axiosEditProduct(access)
      await context.commit('mutationEditProduct', response)
    },
    async actionDetailProduct (context, access) {
      const response = await AxiosManageProduct.axiosDetailProduct(access)
      await context.commit('mutationDetailProduct', response)
    },
    async actionDeletelProduct (context, access) {
      const response = await AxiosManageProduct.axiosDeletelProduct(access)
      await context.commit('mutationDeletelProduct', response)
    },
    async actionListProductByCat (context, access) {
      const response = await AxiosManageProduct.axiosListProductByCat(access)
      await context.commit('mutationListProductByCat', response)
    },
    async actionNSGDefaultUserAddress (context, access) {
      const response = await AxiosManageProduct.axiosDefaultUserAddress(access)
      await context.commit('mutationDefaultUserAddress', response)
    },
    async actionUpdateStatusShowSold (context, access) {
      const response = await AxiosManageProduct.axiosUpdateStatusShowSold(access)
      await context.commit('mutationUpdateStatusShowSold', response)
    },
    async actionDeleteProduct (context, access) {
      const response = await AxiosManageProduct.axiosDeleteProduct(access)
      await context.commit('mutationDeleteProduct', response)
    },
    async actionWholesaleStatus (context, access) {
      const response = await AxiosManageProduct.axiosWholesaleStatus(access)
      await context.commit('mutationWholesaleStatus', response)
    },
    async actionBestSellerProduct (context, access) {
      const response = await AxiosManageProduct.axiosbestSellerProduct(access)
      await context.commit('mutationBestSellerProduct', response)
    },
    async actionBestSellerManage (context, access) {
      const response = await AxiosManageProduct.axiosbestSellerManage(access)
      await context.commit('mutationBestSellerManage', response)
    }
  }
}

export default ModuleManageProduct
