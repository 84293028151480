import AxiosLog from './axios_log_api'

const NSGModuleLog = {
  state: {
    // Add Cart
    stateLog: []
  },
  mutations: {
    // Add Cart
    mutationsLog (state, data) {
      state.stateLog = data
    }
  },
  actions: {
    // Add Cart
    async actionsLog (context, access) {
      const response = await AxiosLog.Log(access)
      await context.commit('mutationsLog', response)
    }
  }
}

export default NSGModuleLog
