import AxiosManageNews from './axios_manage_news'

const ModuleManageNews = {
  state: {
    stateGetNews: [],
    stateGetNewsBanner: [],
    stateManageNewsBanner: [],
    stateCreateNews: [],
    stateEditNews: [],
    stateDetailNews: [],
    stateDeleteNews: [],
    stateGetNewsForEorC: [],
    stateSwitchNewsStatus: [],
    stateCreateGroupNews: [],
    stateEditGroupNews: [],
    stateGetGroupsNews: [],
    stateSetIndexGroupsNews: [],
    stateDeleteGroupsNews: [],
    stateGetDetailGroupsNews: [],
    stateGetNewsBuyer: [],
    stateDetailNewsProduct: [],
    stateGetNewsProduct: [],
    stateRelationNewsProduct: []
  },
  mutations: {
    mutationGetNews (state, data) {
      state.stateGetNews = data
    },
    mutationGetNewsBanner (state, data) {
      state.stateGetNewsBanner = data
    },
    mutationManageNewsBanner (state, data) {
      state.stateManageNewsBanner = data
    },
    mutationCreateNews (state, data) {
      state.stateCreateNews = data
    },
    mutationEditNews (state, data) {
      state.stateEditNews = data
    },
    mutationDetailNews (state, data) {
      state.stateDetailNews = data
    },
    mutationDetailNewsProduct (state, data) {
      state.stateDetailNewsProduct = data
    },
    mutationDeleteNews (state, data) {
      state.stateDeleteNews = data
    },
    mutationGetNewsForEorC (state, data) {
      state.stateGetNewsForEorC = data
    },
    mutationSwitchNewsStatus (state, data) {
      state.stateSwitchNewsStatus = data
    },
    mutationCreateGroupNews (state, data) {
      state.stateCreateGroupNews = data
    },
    mutationEditGroupNews (state, data) {
      state.stateEditGroupNews = data
    },
    mutationGetGroupsNews (state, data) {
      state.stateGetGroupsNews = data
    },
    mutationSetIndexGroupsNews (state, data) {
      state.stateSetIndexGroupsNews = data
    },
    mutationDeleteGroupsNews (state, data) {
      state.stateDeleteGroupsNews = data
    },
    mutationGetDetailGroupsNews (state, data) {
      state.stateGetDetailGroupsNews = data
    },
    mutationGetNewsBuyer (state, data) {
      state.stateGetNewsBuyer = data
    },
    mutationGetNewsProduct (state, data) {
      state.stateGetNewsProduct = data
    },
    mutationRelationNewsProduct (state, data) {
      state.stateRelationNewsProduct = data
    }
  },
  actions: {
    async actionGetNews (context, access) {
      const response = await AxiosManageNews.axiosGetNews(access)
      await context.commit('mutationGetNews', response)
    },
    async actionGetNewsBanner (context, access) {
      const response = await AxiosManageNews.axiosGetNewsBanner(access)
      await context.commit('mutationGetNewsBanner', response)
    },
    async actionManageNewsBanner (context, access) {
      const response = await AxiosManageNews.axiosManageNewsBanner(access)
      await context.commit('mutationManageNewsBanner', response)
    },
    async actionSwitchNewsStatus (context, access) {
      const response = await AxiosManageNews.axiosSwitchNewsStatus(access)
      await context.commit('mutationSwitchNewsStatus', response)
    },
    async actionCreateNews (context, access) {
      const response = await AxiosManageNews.axiosCreateNews(access)
      await context.commit('mutationCreateNews', response)
    },
    async actionEditNews (context, access) {
      const response = await AxiosManageNews.axiosEditNews(access)
      await context.commit('mutationEditNews', response)
    },
    async actionDetailNews (context, access) {
      const response = await AxiosManageNews.axiosDetailNews(access)
      await context.commit('mutationDetailNews', response)
    },
    async actionDetailNewsProduct (context, access) {
      const response = await AxiosManageNews.axiosDetailNewsProduct(access)
      await context.commit('mutationDetailNewsProduct', response)
    },
    async actionDeleteNews (context, access) {
      const response = await AxiosManageNews.axiosDeleteNews(access)
      await context.commit('mutationDeleteNews', response)
    },
    async actionGetNewsForEorC (context, access) {
      const response = await AxiosManageNews.axiosGetNewsForEorC(access)
      await context.commit('mutationGetNewsForEorC', response)
    },
    async actionCreateGroupNews (context, access) {
      const response = await AxiosManageNews.axiosCreateGroupNews(access)
      await context.commit('mutationCreateGroupNews', response)
    },
    async actionEditGroupNews (context, access) {
      const response = await AxiosManageNews.axiosEditGroupNews(access)
      await context.commit('mutationEditGroupNews', response)
    },
    async actionGetGroupsNews (context, access) {
      const response = await AxiosManageNews.axiosGetGroupsNews(access)
      await context.commit('mutationGetGroupsNews', response)
    },
    async actionSetIndexGroupsNews (context, access) {
      const response = await AxiosManageNews.axiosSetIndexGroupsNews(access)
      await context.commit('mutationSetIndexGroupsNews', response)
    },
    async actionDeleteGroupsNews (context, access) {
      const response = await AxiosManageNews.axiosDeleteGroupsNews(access)
      await context.commit('mutationDeleteGroupsNews', response)
    },
    async actionGetDetailGroupsNews (context, access) {
      const response = await AxiosManageNews.axiosGetDetailGroupsNews(access)
      await context.commit('mutationGetDetailGroupsNews', response)
    },
    async actionGetNewsBuyer (context, access) {
      const response = await AxiosManageNews.axiosGetNewsBuyer(access)
      await context.commit('mutationGetNewsBuyer', response)
    },
    async actionGetNewsProduct (context, access) {
      const response = await AxiosManageNews.axiosGetNewsProduct(access)
      await context.commit('mutationGetNewsProduct', response)
    },
    async actionRelationNewsProduct (context, access) {
      const response = await AxiosManageNews.axiosRelationNewsProduct(access)
      await context.commit('mutationRelationNewsProduct', response)
    }
  }
}

export default ModuleManageNews
