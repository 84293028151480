import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    if (Object.keys(oneData).length === 0) {
      const auth = ''
      return auth
    } else {
      const auth = {
        headers: { Authorization: `Bearer ${oneData.user.access_token}` }
      }
      return auth
    }
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // product homepage
  async ProductHomepage (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/list/product_status`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // serach product homepage
  async SearchProduct (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/search/search_bar`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Product Detail
  async GetNSGProductDetail (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}get_product_detail`, data, auth)
      console.log('มาไหม aa7', response.data, 'payload', data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Banner Homepage
  async GetBannerHomepage (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}banner/getBannerDetail`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Prodcut Category Homepage
  async ProductCategoey (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/list/product_category`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Product History Purchaser
  async ProductHistory (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}product/list/product_history`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ProductSelectOption (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}product_select_option`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list_related_product
  async ListRelatedProduct (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}list_related_product`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Order Buyer
  async OrderForBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/list_order_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailOrderForBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/detail_order_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CustomizeLandingPage (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}customize/list_lading_page_buyer`)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckStatusShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}check_status_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}update_status_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckUserAuth () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}check_user_unauthorized`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckUserTransportTracking (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}transport/tracking`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckUserNotPaid (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/list_order_user_notpaid`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckUserTrackingAll (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}transport/trackingAll`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckPopup (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}pop_up/disable_popup`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async FeedbackReview (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/list_order_no_review_top10`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async FeedbackCommentSatisfaction (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/comment_and_satisfaction`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateCommentFeedback (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}feedback/create_comment_feedback`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
