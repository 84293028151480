import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    icons: {
      iconfont: 'mdi'
    },
    themes: {
      light: {
        primary: '#1976D2',
        backgroundTable: '#F3F4F8',
        fontTable: '#9BA0B3',
        success: '#2A70C3'
      }
    }
  }
})
