import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async axiosListRefundSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/list_refund_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailRefundSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/detail_refund_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosApproveRefundSeller (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/approve_refund_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  // Buyer Part
  async axiosListRefundBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/list_refund_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailRefundBuyer (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/detail_refund_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosSendRefund (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}refund/send_refund`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
