import AxiosPermission from './axios_permission'

const ModulePermission = {
  state: {
    stateDetailUserSeller: [],
    stateDetailPosition: [],
    stateCreatePosition: [],
    stateEditPosition: [],
    stateListAllStaff: [],
    statelistPosition2: [],
    statejobTransfer: [],
    stateShowStatusPromotion: [],
    statePermissionListAllStaff: [],
    statePermissionListPosition: [],
    statePermissionListAdmin: [],
    statelistPosition: [],
    statelistSuperAdmin: [],
    stateDeletePosition: [],
    stateDetailSuperAdmin: [],
    stateListStaff: [],
    stateSetUserPosition: [],
    stateListAdmin: [],
    stateDeleteUserSeller: [],
    stateTransferlistAdminStaff: [],
    stateChangeSuperAdmin: [],
    stateSearchNotStaff: [],
    stateSettingEmployee: [],
    stateDetailSettingEmployee: []

  },
  mutations: {
    mutationDetailUserSeller (state, data) {
      state.stateDetailUserSeller = data
    },
    mutationDetailPosition (state, data) {
      state.stateDetailPosition = data
    },
    mutationCreatePosition (state, data) {
      state.stateCreatePosition = data
    },
    mutationEditPosition (state, data) {
      state.stateEditPosition = data
    },
    mutationListAllStaff (state, data) {
      state.stateListAllStaff = data
    },
    mutationlistPosition2 (state, data) {
      state.statelistPosition2 = data
    },
    mutationjobTransfer (state, data) {
      state.statejobTransfer = data
    },
    mutationShowStatusPromotion (state, data) {
      state.stateShowStatusPromotion = data
    },
    mutationPermissionListAllStaff (state, data) {
      state.statePermissionListAllStaff = data
    },
    mutationPermissionListPosition (state, data) {
      state.statePermissionListPosition = data
    },
    mutationPermissionListAdmin (state, data) {
      state.statePermissionListAdmin = data
    },
    mutationlistPosition (state, data) {
      state.statelistPosition = data
    },
    mutationlistSuperAdmin (state, data) {
      state.statelistSuperAdmin = data
    },
    mutationDeletePosition (state, data) {
      state.stateDeletePosition = data
    },
    mutationDetailSuperAdmin (state, data) {
      state.stateDetailSuperAdmin = data
    },
    mutationListStaff (state, data) {
      state.stateListStaff = data
    },
    mutationSetUserPosition (state, data) {
      state.stateSetUserPosition = data
    },
    mutationListAdmin (state, data) {
      state.stateListAdmin = data
    },
    mutationDeleteUserSeller (state, data) {
      state.stateDeleteUserSeller = data
    },
    mutationTransferlistAdminStaff (state, data) {
      state.stateTransferlistAdminStaff = data
    },
    mutationChangeSuperAdmin (state, data) {
      state.stateChangeSuperAdmin = data
    },
    mutationSearchNotStaff (state, data) {
      state.stateSearchNotStaff = data
    },
    mutationSettingEmployee (state, data) {
      state.stateSettingEmployee = data
    },
    mutationDetailSettingEmployee (state, data) {
      state.stateDetailSettingEmployee = data
    }
  },
  actions: {
    async actionDetailUserSeller (context, access) {
      const response = await AxiosPermission.axiosDetailUserSeller(access)
      await context.commit('mutationDetailUserSeller', response)
    },
    async actionDetailPosition (context, access) {
      const response = await AxiosPermission.axiosDetailPosition(access)
      await context.commit('mutationDetailPosition', response)
    },
    async actionCreatePosition (context, access) {
      const response = await AxiosPermission.axiosCreatePosition(access)
      await context.commit('mutationCreatePosition', response)
    },
    async actionEditPosition (context, access) {
      const response = await AxiosPermission.axiosEditPosition(access)
      await context.commit('mutationEditPosition', response)
    },
    async actionListAllStaff (context, access) {
      const response = await AxiosPermission.axiosListAllStaff(access)
      await context.commit('mutationListAllStaff', response)
    },
    async actionlistPosition2 (context, access) {
      const response = await AxiosPermission.axioslistPosition2(access)
      await context.commit('mutationlistPosition2', response)
    },
    async actionjobTransfer (context, access) {
      const response = await AxiosPermission.axiosjobTransfer(access)
      await context.commit('mutationjobTransfer', response)
    },
    async actionShowStatusPromotion (context, access) {
      const response = await AxiosPermission.axiosShowStatusPromotion(access)
      await context.commit('mutationShowStatusPromotion', response)
    },
    async actionPermissionListAllStaff (context, access) {
      const response = await AxiosPermission.axiosPermissionListAllStaff(access)
      await context.commit('mutationPermissionListAllStaff', response)
    },
    async actionPermissionListPosition (context, access) {
      const response = await AxiosPermission.axiosPermissionListPosition(access)
      await context.commit('mutationPermissionListPosition', response)
    },
    async actionPermissionListAdmin (context, access) {
      const response = await AxiosPermission.axiosPermissionListAdmin(access)
      await context.commit('mutationPermissionListAdmin', response)
    },
    async actionlistPosition (context, access) {
      // แสดงข้อมูลทั้งหมดในหน้า กำหนดสิทธิ์การเข้าถึงข้อมูล
      const response = await AxiosPermission.axioslistPosition(access)
      await context.commit('mutationlistPosition', response)
    },
    async actionlistSuperAdmin (context, access) {
      // แสดงข้อมูลทั้งหมดในหน้า กำหนดสิทธิ์การเข้าถึงข้อมูล SuperAdmin
      const response = await AxiosPermission.axioslistSuperAdmin(access)
      await context.commit('mutationlistSuperAdmin', response)
    },
    async actionDeletePosition (context, access) {
      // ลบข้อมูลทั้งหมดในหน้า กำหนดสิทธิ์การเข้าถึงข้อมูล
      const response = await AxiosPermission.axiosDeletePosition(access)
      await context.commit('mutationDeletePosition', response)
    },
    async actionDetailSuperAdmin (context, access) {
      const response = await AxiosPermission.axiosDetailSuperAdmin(access)
      await context.commit('mutationDetailSuperAdmin', response)
    },
    async actionListStaff (context, access) {
      const response = await AxiosPermission.axiosListStaff(access)
      await context.commit('mutationListStaff', response)
    },
    async actionSetUserPosition (context, access) {
      const response = await AxiosPermission.axiosSetUserPosition(access)
      await context.commit('mutationSetUserPosition', response)
    },
    async actionListAdmin (context, access) {
      const response = await AxiosPermission.axiosListAdmin(access)
      await context.commit('mutationListAdmin', response)
    },
    async actionDeleteUserSeller (context, access) {
      const response = await AxiosPermission.axiosDeleteUserSeller(access)
      await context.commit('mutationDeleteUserSeller', response)
    },
    async actionTransferlistAdminStaff (context, access) {
      const response = await AxiosPermission.axiosTransferlistAdminStaff(access)
      await context.commit('mutationTransferlistAdminStaff', response)
    },
    async actionChangeSuperAdmin (context, access) {
      const response = await AxiosPermission.axiosChangeSuperAdmin(access)
      await context.commit('mutationChangeSuperAdmin', response)
    },
    async actionSearchNotStaff (context, access) {
      const response = await AxiosPermission.axiosSearchNotStaff(access)
      await context.commit('mutationSearchNotStaff', response)
    },
    async actionSettingEmployee (context, access) {
      const response = await AxiosPermission.axiosSettingEmployee(access)
      await context.commit('mutationSettingEmployee', response)
    },
    async actionDetailSettingEmployee (context, access) {
      const response = await AxiosPermission.axiosDetailSettingEmployee(access)
      await context.commit('mutationDetailSettingEmployee', response)
    }
  }
}

export default ModulePermission
