import Vue from 'vue'
// import VueGtm from '@gtm-support/vue2-gtm'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const routes = [
  { path: '/RedirectPaymentPage', component: () => import('@/components/Cart/RedirectPayment') },
  { path: '/ResetPassword', name: 'ResetPassword', component: () => import('@/components/NSG/Home/RedirectResetPassword') },
  { path: '/RedirectPortalResetPassword/:data', name: 'RedirectPortalResetPassword', component: () => import('@/components/NSG/Home/RedirectPortalResetPassword') },
  { path: '/redirect', component: () => import('@/views/redirect') },
  { path: '/google', component: () => import('@/views/google') },
  { path: '/line', component: () => import('@/views/line') },
  { path: '/facebook', component: () => import('@/views/facebook') },
  { path: '/monitor/log_function', name: '', component: () => import('@/components/NSG/Shop/LogPage/LogPage') },
  {
    path: '/',
    name: 'NSG',
    component: () => import('@/views/NSG/Home'),
    children: [
      // { path: '/', name: 'NSG', component: () => import('@/views/NSG/HomeProduct') },
      { path: '/PMCC/:id', name: 'PMCC', component: () => import('@/views/NSG/CarbonCopyPayment') },
      { path: '/PMQR/:id', name: 'PMQR', component: () => import('@/views/NSG/QrcodePayment') },
      { path: '/Banner/:id&:names&:component_pro&:brand&:sub_id&:pages', name: 'Banner', component: () => import('../views/Banner') },
      { path: '/Login', name: 'Login', component: () => import('@/components/NSG/Home/Login') },
      { path: '/Register', name: 'Register', component: () => import('@/components/NSG/Home/Register') },
      { path: '/forgetPassword', name: 'forgetPassword', component: () => import('@/components/NSG/Home/ForgetPassword') },
      { path: '/DetailProductNSG/:data', name: 'DetailProductNSG', component: () => import('@/views/NSG/DetailProduct') },
      { path: '/ListProductNSG/:data&:pages', name: 'ListProduct', component: () => import('@/views/NSG/ListProduct') },
      { path: '/Category/:data', name: 'Category', component: () => import('@/views/NSG/Category') },
      { path: '/E-CatalogFolder', name: 'E-CatalogFolder', component: () => import('@/views/NSG/Catalog/CatalogFolders') },
      { path: '/E-Catalog', name: 'E-CatalogPDF', component: () => import('@/views/NSG/Catalog/CatalogPDF') },
      { path: '/AboutUs', name: 'AboutUs', component: () => import('@/views/NSG/AboutUs') },
      { path: '/Payment', name: 'Payment', component: () => import('@/views/NSG/Payment') },
      { path: '/Contact', name: 'Contact', component: () => import('@/views/NSG/Contact') },
      { path: '/termofuse', name: 'termofuse', component: () => import('@/views/NSG/TermOfUse') },
      { path: '/PrivatePolicy', name: 'PrivatePolicy', component: () => import('@/views/NSG/PrivatePolicy') },
      { path: '/DeletingUserData', name: 'DeletingUserData', component: () => import('@/views/NSG/DeletingUserData') },
      { path: '/shoppingcart', name: 'shoppingcartNSG', component: () => import('@/views/NSG/Cart/ShoppingCart') },
      { path: '/checkout', name: 'checkoutNSG', component: () => import('@/views/NSG/Cart/Checkout') },
      { path: '/Blog', name: 'Blog', component: () => import('@/views/NSG/News&Activity') },
      { path: '/BlogDetail', name: 'BlogDetail', component: () => import('@/views/NSG/News&ActivityDetail') },
      { path: '/BlogGroups', name: 'BlogGroups', component: () => import('@/views/NSG/News&ActivityGroups') },
      { path: '/Campaing', name: 'Campaing&Promotion', component: () => import('@/components/NSG/Campaing&Promotion/CampaingList') },
      { path: '/promotionList', name: 'promotionList', component: () => import('@/components/NSG/Campaing&Promotion/PromotionList') },
      { path: '/ProductList', name: 'ProductList', component: () => import('@/components/NSG/Campaing&Promotion/ProductList') },
      { path: '/promotion/:id&:names&:component_pro&:brand&:sub_id&:pages', name: 'promotion', component: () => import('@/views/NSG/Promotion') },
      { path: '/allpromotion', name: 'promotion', component: () => import('@/views/NSG/AllPromotion') },
      { path: '/userMobileNSG', name: 'userMobileNSG', component: () => import('@/views/NSG/UserProfileMobile') },
      { path: '/userprofileMobileNSG', name: 'userprofileNSG', component: () => import('@/components/NSG/UserProfile/UserProfile') },
      { path: '/addressProfileMobileNSG', name: 'addressProfileNSG', component: () => import('@/components/NSG/UserProfile/AddressProfile') },
      { path: '/pobuyerProfileMobileNSG', name: 'pobuyeruiProfileNSG', component: () => import('@/views/NSG/POBuyerProfile') },
      { path: '/pobuyerdetailMobileNSG', name: 'pobuyerdetailNSG', component: () => import('@/components/NSG/UserProfile/POBuyerDetail') },
      { path: '/historyPurchaserMobileNSG', name: 'historyPurchaser', component: () => import('@/components/NSG/UserProfile/historyPurchaser') },
      { path: '/myVoucherMobileNSG', name: 'voucher', component: () => import('@/components/NSG/UserProfile/myVoucher') },
      { path: '/member&privilegeMobileNSG', name: 'member&privilege', component: () => import('@/components/NSG/UserProfile/Member&Privilege') },
      { path: '/favoriteListMobile', name: 'FavoriteList', component: () => import('@/components/NSG/UserProfile/favoriteList') },
      { path: '/manageCustomerMobile', name: 'manageCustomer', component: () => import('@/components/NSG/Shop/ManagecustomerMobile') },
      { path: '/CreateCustomerMobile', name: 'CreateCustomer', component: () => import('@/components/NSG/Shop/CreateCustomerMobile') },
      { path: '/voucherMainNSG', name: 'voucherMainNSG', component: () => import('@/views/NSG/​HomeVoucher') },
      { path: '/OrderRatingMobile', name: 'OrderRating', component: () => import('@/components/NSG/UserProfile/OrderRating') },
      { path: '/FlashSaleNSG/:pages', name: 'FlashSaleNSG', component: () => import('@/views/NSG/FlashSale/FlashSaleAll') },
      { path: '/voucherDetailNSG/:data', name: 'voucherDetailNSG', component: () => import('@/components/NSG/Voucher/DetailVoucher') },
      { path: '/QuotationDetail', name: 'QutationDetailNSG', component: () => import('@/components/NSG/Quotation/QuotationSeller/QoutationDetailNSG') },
      { path: '/ProductBlog/:Id', name: 'ProductBlog', component: () => import('@/views/NSG/Product&News&Activity') },
      { path: '/ProductBlogDetails/:newsId', name: 'ProductBlogDetails', component: () => import('@/views/NSG/Product&News&ActivityDetail') },
      { path: '/Rating&Comment', name: 'Rating&Comment', component: () => import('@/components/NSG/Rating&Comment/Rating&Comment') },
      {
        path: '/userprofileNSG',
        component: () => import('../views/NSG/UserProfile'),
        children: [
          { path: '/userprofileNSG', name: 'userprofileNSG', component: () => import('@/components/NSG/UserProfile/UserProfile') },
          { path: '/addressProfileNSG', name: 'addressProfileNSG', component: () => import('@/components/NSG/UserProfile/AddressProfile') },
          { path: '/pobuyerProfileNSG', name: 'pobuyeruiProfileNSG', component: () => import('@/views/NSG/POBuyerProfile') },
          { path: '/pobuyerdetailNSG', name: 'pobuyerdetailNSG', component: () => import('@/components/NSG/UserProfile/POBuyerDetail') },
          { path: '/historyPurchaser', name: 'historyPurchaser', component: () => import('@/components/NSG/UserProfile/historyPurchaser') },
          { path: '/OrderRating', name: 'OrderRating', component: () => import('@/components/NSG/UserProfile/OrderRating') },
          { path: '/voucher', name: 'voucher', component: () => import('@/components/NSG/UserProfile/Voucher') },
          { path: '/myVoucher', name: 'myVoucher', component: () => import('@/components/NSG/UserProfile/myVoucher') },
          { path: '/member&privilege', name: 'member&privilege', component: () => import('@/components/NSG/UserProfile/Member&Privilege') },
          // { path: '/creditProfileNSG', name: 'creditProfileNSG', component: () => import('@/components/NSG/UserProfile/CreditProfile') },
          { path: '/favoriteList', name: 'FavoriteList', component: () => import('@/components/NSG/UserProfile/favoriteList') },
          { path: '/returnRefund', name: 'returnRefund', component: () => import('@/components/NSG/UserProfile/ReturnRefund') },
          { path: '/returnRefundDetail', name: 'returnRefundDetail', component: () => import('@/components/NSG/UserProfile/ReturnRefundDetail') },
          { path: '/tracking', name: 'tracking', component: () => import('@/components/NSG/UserProfile/Tracking') },
          { path: '/myqoutation', name: 'myqoutation', component: () => import('@/components/NSG/UserProfile/QuotationBuyer') },
          // path name wording incorrect
          { path: '/myqoutationDetail/:id', name: 'myqoutationDetail', component: () => import('@/components/NSG/UserProfile/QuotationDetail') }
        ]
      }
    ]
  },
  {
    path: '/Seller',
    component: () => import('../views/NSG/Shop/Home'),
    children: [
      { path: '/sellerNSGs', name: 'sellerNSGs', component: () => import('@/components/NSG/Shop/FirstTimeSeller') },
      { path: '/seller', name: 'seller', component: () => import('@/components/NSG/Shop/Product/ProductList') },
      { path: '/userprofileSeller', name: 'userprofileSeller', component: () => import('@/components/NSG/Shop/userprofileSeller') },
      { path: '/manageProductImport', name: 'manageProductImport', component: () => import('@/components/NSG/Shop/Product/ProductImportPreview') },
      { path: '/manageProductCreate', name: 'manageProductCreate', component: () => import('@/components/NSG/Shop/Product/ProductCreate') },
      { path: '/manageProductEdit', name: 'manageProductEdit', component: () => import('@/components/NSG/Shop/Product/ProductEdit') },
      { path: '/manageBanner', name: 'manageBanner', component: () => import('@/components/NSG/Shop/ManageBanner') },
      { path: '/manageNews', name: 'manageNews', component: () => import('@/components/NSG/Shop/ManageNews/ManageNewsList') },
      { path: '/manageNewsCreate', name: 'manageNewsCreate', component: () => import('@/components/NSG/Shop/ManageNews/ManageNewsCreate') },
      { path: '/manageNewsEdit/:id', name: 'manageNewsEdit', component: () => import('@/components/NSG/Shop/ManageNews/ManageNewsEdit') },
      { path: '/manageCategoryNews', name: 'manageCategoryNews', component: () => import('@/components/NSG/Shop/ManageNews/ManageCategoryNews') },
      { path: '/createCategoryNews', name: 'createCategoryNews', component: () => import('@/components/NSG/Shop/ManageNews/CreateCategoryNews') },
      { path: '/editCategoryNews/:id', name: 'editCategoryNews', component: () => import('@/components/NSG/Shop/ManageNews/EditCategoryNews') },
      { path: '/detailCategoryNews/:id', name: 'detailCategoryNews', component: () => import('@/components/NSG/Shop/ManageNews/DetailCategoryNews') },
      { path: '/managePopUp', name: 'managePopUp', component: () => import('@/views/NSG/Shop/ManagePopUp') },
      { path: '/managePopUpCreate', name: 'managePopUpCreate', component: () => import('@/components/NSG/Shop/ManagePopUpCreate') },
      { path: '/managePopUpEdit', name: 'managePopUpEdit', component: () => import('@/components/NSG/Shop/ManagePopUpEdit') },
      { path: '/managePromotion', name: 'managePromotion', component: () => import('@/components/NSG/Shop/ManagePromotion/ManagePromotion') },
      { path: '/createPromotion', name: 'createPromotion', component: () => import('@/components/NSG/Shop/ManagePromotion/CreatePromotion') },
      { path: '/updatePromotion/:id', name: 'updatePromotion', component: () => import('@/components/NSG/Shop/ManagePromotion/UpdatePromotion') },
      { path: '/addPromotionProduct/:id', name: 'addPromotionProduct', component: () => import('@/components/NSG/Shop/ManagePromotion/AddPromotionProduct') },
      { path: '/managePromotionProduct/:id', name: 'managePromotionProduct', component: () => import('@/components/NSG/Shop/ManagePromotion/ManagePromotionProduct') },
      { path: '/manageSortProduct', name: 'manageSortProduct', component: () => import('@/components/NSG/SortProduct/SortProduct') },
      { path: '/categoryBanner', name: 'categoryBanner', component: () => import('@/components/NSG/Shop/MangeCategoryBanner') },
      { path: '/coupon', name: 'coupon', component: () => import('@/components/NSG/Shop/Coupon') },
      { path: '/manageMemberClass', name: 'manageMemberClass', component: () => import('@/components/NSG/Shop/ManageMemberClass/MemberList') },
      { path: '/manageMember', name: 'manageMember', component: () => import('@/components/NSG/Shop/ManageMemberClass/ManageMember') },
      { path: '/managecomments', name: 'managecomments', component: () => import('@/components/NSG/Shop/ManageComments/SellerManageComment') },
      // { path: '/managecommentsEditVer', name: 'managecomments', component: () => import('@/components/NSG/Shop/ManageComments/SellerManageCommentEditVer') },
      { path: '/detailComment/:id', name: 'detailComment', component: () => import('@/components/NSG/Shop/ManageComments/SellerDetailComment') },
      { path: '/campaign&promotions', name: 'campaign&promotions', component: () => import('@/views/NSG/Shop/Campaign&Promotions') },
      { path: '/campaign', name: 'campaign', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Campaign/AllCampaigne') },
      { path: '/manageCampaign', name: 'manageCampaign', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Campaign/ManageCampaign') },
      { path: '/pointManage', name: 'pointManage', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Promotions/Point/PointManage') },
      { path: '/shippingManage', name: 'shippingManage', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Promotions/Shipping/ShippingManage') },
      { path: '/discountManage', name: 'discountManage', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Promotions/Discount/DiscountManage') },
      { path: '/buy1Free1', name: 'buy1Free1', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Promotions/Buy1Free1/Buy1Free1List') },
      { path: '/buy1Free1Manage', name: 'buy1Free1Manage', component: () => import('@/components/NSG/Shop/Campaign&Promotions/Promotions/Buy1Free1/Buy1Free1Manage') },
      { path: '/manageOrder', name: 'manageOrder', component: () => import('@/views/NSG/Shop/POSeller') },
      { path: '/manageOrderDetail', name: 'manageOrderDetail', component: () => import('@/components/NSG/Quotation/POSellerDetail') },
      { path: '/manageCancelOrder', name: 'manageCancelOrder', component: () => import('@/components/NSG/Shop/ManageCancelOrder/ManageCancelOrderList') },
      { path: '/manageCancelOrderDetail', name: 'manageCancelOrderDetail', component: () => import('@/components/NSG/Shop/ManageCancelOrder/ManageCancelOrderDetail') },
      { path: '/manageRatesDynamic', name: 'manageRatesDynamic', component: () => import('@/components/NSG/Shop/ManageRatesDynamic1') },
      { path: '/manageRatesDynamicDetail', name: 'manageRatesDynamicDetail', component: () => import('@/components/NSG/Shop/ManageRatesDynamicDetail') },
      { path: '/manageQuotationSeller', name: 'manageQuotationSeller', component: () => import('@/components/NSG/Shop/ManageQuotationSeller') },
      { path: '/manageDetailQuotationSeller/:id', name: 'manageDetailQuotationSeller', component: () => import('@/components/NSG/Shop/ManageDetailQuotationSeller') },
      { path: '/manageFlashSale', name: 'manageFlashSale', component: () => import('@/components/NSG/Shop/ManageFlashSale/ManageFlashSale') },
      { path: '/manageFlashSaleCreate', name: 'manageFlashSaleCreate', component: () => import('@/components/NSG/Shop/ManageFlashSale/ManageFlashSaleCreate') },
      { path: '/manageFlashSaleEdit', name: 'manageFlashSaleEdit', component: () => import('@/components/NSG/Shop/ManageFlashSale/ManageFlashSaleEdit') },
      { path: '/manageFlashSaleDetail', name: 'manageFlashSaleDetail', component: () => import('@/components/NSG/Shop/ManageFlashSale/ManageFlashSaleDetail') },
      { path: '/manageVoucher', name: 'manageVoucher', component: () => import('@/components/NSG/Shop/Vouchers/Vouchers') },
      { path: '/manageVoucherCreateEdit', name: 'manageVoucherCreateEdit', component: () => import('@/components/NSG/Shop/Vouchers/VoucherManage') },
      // { path: '/manageVoucherCreate', name: 'manageVoucher', component: () => import('@/components/NSG/Shop/Vouchers/VoucherEvant/VoucherEvantCreate') },
      // { path: '/manageVoucherAction/:data', name: 'createVoucher', component: () => import('@/components/NSG/Shop/Vouchers/VoucherManage') },
      { path: '/manageUseVoucher', name: 'useVoucher', component: () => import('@/components/NSG/Voucher/useVouchers') },
      // { path: '/editVoucher/:data', name: 'editVoucher', component: () => import('@/components/NSG/Shop/Vouchers/VoucherEvant/VoucherEvantEdit') },
      { path: '/dashboard', name: 'Dashboard', component: () => import('@/views/NSG/Shop/Dashboard') },
      { path: '/dashboardCampaign', name: 'dashboardCampaign', component: () => import('@/views/NSG/Shop/Dashboard&Campaign') },
      { path: '/manageShipping', name: 'manageShipping', component: () => import('@/components/NSG/Shop/Shipping/ManageShipping') },
      { path: '/createShipping', name: 'createShipping', component: () => import('@/components/NSG/Shop/Shipping/CreateShipping') },
      { path: '/divideorder/:Id', name: 'divideorder', component: () => import('@/components/NSG/Shop/Shipping/DivideOrder') },
      { path: '/relatedProduct/:id', name: 'relatedProduct', component: () => import('@/components/NSG/Shop/RelatedProduct/ManageRelatedProduct') },
      { path: '/manageUserSeller', name: 'manageUserSeller', component: () => import('@/components/NSG/Shop/ManageUserSeller') },
      { path: '/chatForSeller', name: 'chatForSeller', component: () => import('@/components/NSG/Shop/chatSeller') },
      { path: '/landingPage', name: 'landingPage', component: () => import('@/components/NSG/Shop/ManageLandingPage/LandingPage') },
      { path: '/manageLandingPage', name: 'manageLandingPage', component: () => import('@/components/NSG/Shop/ManageLandingPage/ManageLandingPage') },
      { path: '/productInLanding', name: 'productInLanding', component: () => import('@/components/NSG/Shop/ManageLandingPage/ProductList') },
      { path: '/manageReturnRefund', name: 'manageReturnRefund', component: () => import('@/components/NSG/Shop/ManageReturnRefund/ReturnRefund') },
      { path: '/manageReturnRefundDetail', name: 'manageReturnRefundDetail', component: () => import('@/components/NSG/Shop/ManageReturnRefund/ReturnDetail') },
      { path: '/manageCategpry', name: 'manageCategory', component: () => import('@/views/NSG/Shop/ManageCategory') },
      { path: '/manageUserSellerDetail', name: 'manageUserSellerDetail', component: () => import('@/components/NSG/Shop/ManageUserSellerDetail') },
      { path: '/manageReport', name: 'manageReport', component: () => import('@/components/NSG/Shop/Report/ReportMain') },
      { path: '/supperAdminInfo', name: 'supperAdminInfo', component: () => import('@/components/NSG/Permission/ManageStaff/SuperAdmin/ManageSuperAdmin') },
      { path: '/supperAdminInfoDetail', name: 'supperAdminInfoDetail', component: () => import('@/components/NSG/Permission/ManageStaff/SuperAdmin/ManageSuperAdminDetail') },
      { path: '/ManageSuperAdminTransfer', name: 'ManageSuperAdminTransfer', component: () => import('@/components/NSG/Permission/ManageStaff/SuperAdmin/ManageSuperAdminTransfer') },
      { path: '/AdminInfo', name: 'AdminInfo', component: () => import('@/components/NSG/Permission/ManageStaff/Admin/ManageAdmin') },
      { path: '/userAllInfo', name: 'userAllInfo', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/UserPermissionDetails') },
      { path: '/userInfo', name: 'userInfo', component: () => import('@/components/NSG/Permission/User/UserInfo') },
      { path: '/userPermission', name: 'userPermission', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/UserPermission') },
      { path: '/userPermission/details', name: 'userPermissionDetails', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/UserPermissionDetails') },
      { path: '/userPermission/adminDetails', name: 'userPermissionAdminDetails', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/UserPermissionAdminDetails') },
      { path: '/manageUserPermission', name: 'manageUserPermission', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/ManageUserPermission') },
      { path: '/createUserPermission', name: 'createUserPermission', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/CreatePermission') },
      { path: '/editUserPermission', name: 'editUserPermission', component: () => import('@/components/NSG/Permission/ManageStaff/UserPermission/EditPermission') },
      { path: '/saleMan', name: 'saleMan', component: () => import('@/components/NSG/Shop/SaleMan/SaleMan') },
      { path: '/orderDetailSaleMan', name: 'orderDetailSaleMan', component: () => import('@/components/NSG/Quotation/POSellerDetail') },
      { path: '/manageBestseller', name: 'manageBestseller', component: () => import('@/components/NSG/Shop/ManageBestSeller/ManageBestSeller') },
      { path: '/dashboardComment', name: 'dashboardComment', component: () => import('@/components/NSG/Shop/ManageComments/DashboardComment/DashboardComment') },
      { path: '/dashboardVoucher', name: 'DashboardVoucher', component: () => import('@/views/NSG/Shop/DashboardVoucher') },
      { path: '/userManual', name: 'userManual', component: () => import('@/components/NSG/Shop/UserManual/UserManual') }
    ]
  },
  { path: '*', component: () => import('@/views/notFoundWeb') },
  { path: '/404', component: () => import('@/views/404') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.name !== 'Banner') {
      if (to.name !== 'NSG') {
        let scrollTo = 0
        if (to.hash) {
          scrollTo = to.hash
        } else if (savedPosition) {
          scrollTo = savedPosition.y
        } else {
          return { x: 0, y: 0 }
        }
        return goTo(scrollTo)
      } else {
        return { x: 0, y: 0 }
      }
    } else {
    }
  }
})

// Vue.use(VueGtm, {
//   id: 'GTM-KG3476F',
//   queryParams: {
//     gtm_auth: 'ZwqHb0tQWN5GKqcWv7pXsg',
//     gtm_preview: 'env-1',
//     gtm_cookies_win: 'x'
//   },
//   defer: false,
//   compatibility: false,
//   nonce: '2726c7f26c',
//   enabled: true,
//   debug: true,
//   loadScript: true,
//   vueRouter: router,
//   ignoredViews: ['homepage'],
//   trackOnNextTick: false
// })

export default router

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected
  const pageAccessedByReload = performance.getEntriesByType('navigation')[0].type === 'back_forward'
  if (IsItABackButton === false && from.name === null) {
    if (pageAccessedByReload === true) {
      window.history.go(-1)
      next()
      return false
    }
    next()
  }
  next()
})
