import AxiosManageQuotation from './axios_manage_quotation'

const NSGManageQuotation = {
  state: {
    stateGetQuotationlistSeller: [],
    stateGetQuotationDetailSeller: [],
    stateAcceptQuotationSeller: [],
    stateCancelQuotationSeller: [],
    stateExportQuotationSeller: []
  },
  mutations: {
    mutationGetQuotationlistSeller (state, data) {
      state.stateGetQuotationlistSeller = data
    },
    mutationGetQuotationDetailSeller (state, data) {
      state.stateGetQuotationDetailSeller = data
    },
    mutationAcceptQuotationSeller (state, data) {
      state.stateAcceptQuotationSeller = data
    },
    mutationCancelQuotationSeller (state, data) {
      state.stateCancelQuotationSeller = data
    },
    mutationExportQuotationSeller (state, data) {
      state.stateExportQuotationSeller = data
    }
  },
  actions: {
    async actionGetQoutationlistSeller (context, access) {
      const response = await AxiosManageQuotation.GetQuotationlistSeller(access)
      await context.commit('mutationGetQuotationlistSeller', response)
    },
    async actionGetQuotationDetailSeller (context, access) {
      const response = await AxiosManageQuotation.GetQuotationDetailSeller(access)
      await context.commit('mutationGetQuotationDetailSeller', response)
    },
    async actionAcceptQuotationSeller (context, access) {
      const response = await AxiosManageQuotation.AcceptQuotationSeller(access)
      await context.commit('mutationAcceptQuotationSeller', response)
    },
    async actionCancelQuotationSeller (context, access) {
      const response = await AxiosManageQuotation.CancelQuotationSeller(access)
      await context.commit('mutationCancelQuotationSeller', response)
    },
    async actionExportQuotationSeller (context, access) {
      const response = await AxiosManageQuotation.ExportQuotationSeller(access)
      await context.commit('mutationExportQuotationSeller', response)
    }
  }
}

export default NSGManageQuotation
