import AxiosShopNSG from './axios_shop_api'

const NSGModuleShop = {
  state: {
    statePostCreatePopUp: [],
    stateGetPopup: [],
    itemSetPopupEdit: {},
    stateGetBanner: [],
    stateAddBanner: [],
    stateReviewProduct: [],
    stateMemberClass: [],
    stateEditMemberClass: [],
    // Order Seller
    stateOrderForSeller: [],
    stateDetailOrderForSeller: [],
    stateRelatedProductSeller: [],
    stateImportExcel: [],
    stateListUserForSeller: [],
    stateCancelOrder: [],
    stateConfirmOrder: [],
    stateListRelated: [],
    stateListHistotyUpdate: [],
    stateDetailHistotyUpdate: [],
    stateUpdateRelated: [],
    stateRandomRelated: [],
    stateExportExcel: [],
    statefilterRelated: [],
    stateRemoveRelated: [],
    stateTemplateProduct: [],
    stateExportDataInSku: [],
    stateDetailWMSFail: [],
    stateSendWMSFail: [],
    stateEditInvoice: [],
    stateSettingEditInvoice: [],
    stateAddSearchBanner: [],
    stateSentChat: [],
    stateDashboardNewProduct: [],
    stateDashboardAllProduct: [],
    stateDashboardExportNew: [],
    stateDashboardExportAll: [],
    stateListCancelOrderSeller: [],
    stateDetailCancelOrderSeller: [],
    stateUpdateStatusCancelOrderSeller: [],
    stateChangeStatusCancelOrderSeller: [],
    // stateListAllSaleMan: []
    stateSentEtax: []

  },
  mutations: {
    mutationPostCreatePopUp (state, data) {
      state.statePostCreatePopUp = data
    },
    mutationGetPopUp (state, data) {
      state.stateGetPopup = data
    },
    mutationGetBanner (state, data) {
      state.stateGetBanner = data
    },
    mutationAddBanner (state, data) {
      state.stateAddBanner = data
    },
    mutationsReviewProduct (state, data) {
      state.stateReviewProduct = data
    },
    mutationsMemberClass (state, data) {
      state.stateMemberClass = data
    },
    mutationsEditMemberClass (state, data) {
      state.stateEditMemberClass = data
    },
    // Order Seller
    mutationsOrderForSeller (state, data) {
      state.stateOrderForSeller = data
    },
    mutationsDetailOrderForSeller (state, data) {
      state.stateDetailOrderForSeller = data
    },
    mutationsRelatedProductSeller (state, data) {
      state.stateRelatedProductSeller = data
    },
    mutationsImportExcel (state, data) {
      state.stateImportExcel = data
    },
    mutationsListUserForSeller (state, data) {
      state.stateListUserForSeller = data
    },
    mutationsCancelOrder (state, data) {
      state.stateCancelOrder = data
    },
    mutationsConfirmOrder (state, data) {
      state.stateConfirmOrder = data
    },
    mutationsListRelated (state, data) {
      state.stateListRelated = data
    },
    mutationsListHistotyUpdate (state, data) {
      state.stateListHistotyUpdate = data
    },
    mutationsDetailHistotyUpdate (state, data) {
      state.stateDetailHistotyUpdate = data
    },
    mutationsUpdateRelated (state, data) {
      state.stateUpdateRelated = data
    },
    mutationsRandomRelated (state, data) {
      state.stateRandomRelated = data
    },
    mutationsExportExcel (state, data) {
      state.stateExportExcel = data
    },
    mutationsfilterRelated (state, data) {
      state.statefilterRelated = data
    },
    mutationsRemoveRelated (state, data) {
      state.stateRemoveRelated = data
    },
    mutationsTemplateProduct (state, data) {
      state.stateTemplateProduct = data
    },
    mutationsExportDataInSku (state, data) {
      state.stateExportDataInSku = data
    },
    mutationsDetailWMSFail (state, data) {
      state.stateDetailWMSFail = data
    },
    mutationsSendWMSFail (state, data) {
      state.stateSendWMSFail = data
    },
    mutationsEditInvoice (state, data) {
      state.stateEditInvoice = data
    },
    mutationsSettingEditInvoice (state, data) {
      state.stateSettingEditInvoice = data
    },
    mutationAddSearchBanner (state, data) {
      state.stateAddSearchBanner = data
    },
    mutationSentChat (state, data) {
      state.stateSentChat = data
    },
    mutationDashboardNewProduct (state, data) {
      state.stateDashboardNewProduct = data
    },
    mutationDashboardAllProduct (state, data) {
      state.stateDashboardAllProduct = data
    },
    mutationDashboardExportNew (state, data) {
      state.stateDashboardExportNew = data
    },
    mutationDashboardExportAll (state, data) {
      state.stateDashboardExportAll = data
    },
    mutationsListCancelOrderSeller (state, data) {
      state.stateListCancelOrderSeller = data
    },
    mutationsDetailCancelOrderSeller (state, data) {
      state.stateDetailCancelOrderSeller = data
    },
    mutationsUpdateStatusCancelOrderSeller (state, data) {
      state.stateUpdateStatusCancelOrderSeller = data
    },
    mutationsChangeStatusCancelOrderSeller (state, data) {
      state.stateChangeStatusCancelOrderSeller = data
    },
    // mutationsListAllSaleMan (state, data) {
    //   state.stateListAllSaleMan = data
    // }
    mutationsstateSentEtax (state, data) {
      state.stateSentEtax = data
    }
  },
  actions: {
    async actionPostCreatePopUp (context, access) {
      const response = await AxiosShopNSG.axiosPostCreate(access)
      await context.commit('mutationPostCreatePopUp', response)
    },
    async actionGetPopUp (context) {
      const response = await AxiosShopNSG.axiosGetPopup()
      await context.commit('mutationGetPopUp', response)
    },
    async actionGetBanner (context, access) {
      const response = await AxiosShopNSG.axiosGetBanner(access)
      await context.commit('mutationGetBanner', response)
    },
    async actionAddBanner (context, access) {
      const response = await AxiosShopNSG.axiosAddBanner(access)
      await context.commit('mutationAddBanner', response)
    },
    async actionsReviewProduct (context, access) {
      const response = await AxiosShopNSG.getReviewProduct(access)
      await context.commit('mutationsReviewProduct', response)
    },
    async actionsMemberClass (context) {
      const response = await AxiosShopNSG.getMemberClass()
      await context.commit('mutationsMemberClass', response)
    },
    async actionsEditMemberClass (context, access) {
      const response = await AxiosShopNSG.editMemberClass(access)
      await context.commit('mutationsEditMemberClass', response)
    },
    // Order Seller
    async actionsOrderForSeller (context, access) {
      const response = await AxiosShopNSG.OrderForSeller(access)
      await context.commit('mutationsOrderForSeller', response)
    },
    async actionsDetailOrderForSeller (context, access) {
      const response = await AxiosShopNSG.DetailOrderForSeller(access)
      await context.commit('mutationsDetailOrderForSeller', response)
    },
    async actionsRelatedProductSeller (context, access) {
      const response = await AxiosShopNSG.RelatedProductSeller(access)
      await context.commit('mutationsRelatedProductSeller', response)
    },
    async actionsImportExcel (context, access) {
      const response = await AxiosShopNSG.ImportExcel(access)
      await context.commit('mutationsImportExcel', response)
    },
    async actionsListUserForSeller (context) {
      const response = await AxiosShopNSG.ListUserForSeller()
      await context.commit('mutationsListUserForSeller', response)
    },
    async actionsCancelOrder (context, access) {
      const response = await AxiosShopNSG.CancelOrder(access)
      await context.commit('mutationsCancelOrder', response)
    },
    async actionsConfirmOrder (context, access) {
      const response = await AxiosShopNSG.ConfirmOrder(access)
      await context.commit('mutationsConfirmOrder', response)
    },
    async actionsListRelated (context, access) {
      const response = await AxiosShopNSG.ListRelated(access)
      await context.commit('mutationsListRelated', response)
    },
    async actionsListHistotyUpdate (context, access) {
      const response = await AxiosShopNSG.ListHistotyUpdate(access)
      await context.commit('mutationsListHistotyUpdate', response)
    },
    async actionsDetailHistotyUpdate (context, access) {
      const response = await AxiosShopNSG.DetailHistotyUpdate(access)
      await context.commit('mutationsDetailHistotyUpdate', response)
    },
    async actionsUpdateRelated (context, access) {
      const response = await AxiosShopNSG.UpdateRelated(access)
      await context.commit('mutationsUpdateRelated', response)
    },
    async actionsRandomRelated (context, access) {
      const response = await AxiosShopNSG.RandomRelated(access)
      await context.commit('mutationsRandomRelated', response)
    },
    async actionsExportExcel (context, access) {
      const response = await AxiosShopNSG.excelExport(access)
      await context.commit('mutationsExportExcel', response)
    },
    async actionsfilterRelated (context, access) {
      const response = await AxiosShopNSG.filterRelated(access)
      await context.commit('mutationsfilterRelated', response)
    },
    async actionsRemoveRelated (context, access) {
      const response = await AxiosShopNSG.RemoveRelated(access)
      await context.commit('mutationsRemoveRelated', response)
    },
    async actionsTemplateProduct (context, access) {
      const response = await AxiosShopNSG.TemplateProduct(access)
      await context.commit('mutationsTemplateProduct', response)
    },
    async actionsExportDataInSku (context) {
      const response = await AxiosShopNSG.ExportDataInSku()
      await context.commit('mutationsExportDataInSku', response)
    },
    async actionsDetailWMSFail (context, access) {
      const response = await AxiosShopNSG.DetailWMSFail(access)
      await context.commit('mutationsDetailWMSFail', response)
    },
    async actionsSendWMSFail (context, access) {
      const response = await AxiosShopNSG.SendWMSFail(access)
      await context.commit('mutationsSendWMSFail', response)
    },
    async actionsEditInvoice (context, access) {
      const response = await AxiosShopNSG.EditInvoice(access)
      await context.commit('mutationsEditInvoice', response)
    },
    async actionsSettingEditInvoice (context, access) {
      const response = await AxiosShopNSG.SettingEditInvoice(access)
      await context.commit('mutationsSettingEditInvoice', response)
    },
    async actionAddSearchBanner (context, access) {
      const response = await AxiosShopNSG.AddSearchBanner(access)
      await context.commit('mutationAddSearchBanner', response)
    },
    async actionSentChat (context, access) {
      const response = await AxiosShopNSG.SentChat(access)
      await context.commit('mutationSentChat', response)
    },
    async actionDashboardNewProduct (context, access) {
      const response = await AxiosShopNSG.DashboardNewProduct(access)
      await context.commit('mutationDashboardNewProduct', response)
    },
    async actionDashboardAllProduct (context, access) {
      const response = await AxiosShopNSG.DashboardAllProduct(access)
      await context.commit('mutationDashboardAllProduct', response)
    },
    async actionDashboardExportNew (context, access) {
      const response = await AxiosShopNSG.DashboardExportNew(access)
      await context.commit('mutationDashboardExportNew', response)
    },
    async actionDashboardExportAll (context, access) {
      const response = await AxiosShopNSG.DashboardExportAll(access)
      await context.commit('mutationDashboardExportAll', response)
    },
    async actionsListCancelOrderSeller (context, access) {
      const response = await AxiosShopNSG.ListCancelOrderSeller(access)
      await context.commit('mutationsListCancelOrderSeller', response)
    },
    async actionsDetailCancelOrderSeller (context, access) {
      const response = await AxiosShopNSG.DetailCancelOrderSeller(access)
      await context.commit('mutationsDetailCancelOrderSeller', response)
    },
    async actionsUpdateStatusCancelOrderSeller (context, access) {
      const response = await AxiosShopNSG.UpdateStatusCancelOrderSeller(access)
      await context.commit('mutationsUpdateStatusCancelOrderSeller', response)
    },
    async actionsChangeStatusCancelOrderSeller (context, access) {
      const response = await AxiosShopNSG.ChangeStatusCancelOrderSeller(access)
      await context.commit('mutationsChangeStatusCancelOrderSeller', response)
    },
    // async actionsListAllSaleMan (context, access) {
    //   const response = await AxiosShopNSG.ListAllSaleMan(access)
    //   await context.commit('mutationsListAllSaleMan', response)
    // }
    async actionsSentEtax (context, access) {
      const response = await AxiosShopNSG.SentEtax(access)
      await context.commit('mutationsstateSentEtax', response)
    }
  }
}

export default NSGModuleShop
