import AxiosInventory from './axios_inventory_api'

const ModuleInventory = {
  state: {
    stateGetInventoryData: []
  },
  mutations: {
    mutationsGetInventoryData (state, data) {
      state.stateGetInventoryData = data
    }
  },
  actions: {
    // Get Inventory Data
    async actionsGetInventoryData (context, access) {
      const responseData = await AxiosInventory.GetInventoryData(access)
      await context.commit('mutationsGetInventoryData', responseData)
    }
  }
}
export default ModuleInventory
