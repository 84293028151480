import AxiosMember from './axios_member&privilege'

const ModuleMember = {
  state: {
    statePointUser: [],
    stateMemberClass: [],
    statePromotionUser: [],
    stateListMemberClass: [],
    stateDeleteCampaign: [],
    stateListMemberClassUser: [],
    stateEditMemberClassUser: [],
    statePointUserForAdmin: [],
    stateSendPointUser: [],
    stateTransferPoint: [],
    stateDetailMemberUser: []
  },
  mutations: {
    mutationPointUser (state, data) {
      state.statePointUser = data
    },
    mutationMemberClass (state, data) {
      state.stateMemberClass = data
    },
    mutationPromotionUser (state, data) {
      state.statePromotionUser = data
    },
    mutationListMemberClass (state, data) {
      state.stateListMemberClass = data
    },
    mutationDeleteCampaign (state, data) {
      state.stateDeleteCampaign = data
    },
    mutationListMemberClassUser (state, data) {
      state.stateListMemberClassUser = data
    },
    mutationEditMemberClassUser (state, data) {
      state.stateEditMemberClassUser = data
    },
    mutationPointUserForAdmin (state, data) {
      state.statePointUserForAdmin = data
    },
    mutationSendPointUser (state, data) {
      state.stateSendPointUser = data
    },
    mutationTransferPoint (state, data) {
      state.stateTransferPoint = data
    },
    mutationDetailMemberUser (state, data) {
      state.stateDetailMemberUser = data
    }
  },
  actions: {
    async actionPointUser (context, access) {
      const response = await AxiosMember.axiosPointUser(access)
      await context.commit('mutationPointUser', response)
    },
    async actionMemberClass (context, access) {
      const response = await AxiosMember.axiosMemberClass(access)
      await context.commit('mutationMemberClass', response)
    },
    async actionPromotionUser (context, access) {
      const response = await AxiosMember.axiosPromotionUser(access)
      await context.commit('mutationPromotionUser', response)
    },
    async actionListMemberClass (context, access) {
      const response = await AxiosMember.axiosListMemberClass(access)
      await context.commit('mutationListMemberClass', response)
    },
    async actionDeleteCampaign (context, access) {
      const response = await AxiosMember.axiosDeleteCampaign(access)
      await context.commit('mutationDeleteCampaign', response)
    },
    async actionListMemberClassUser (context, access) {
      const response = await AxiosMember.axiosListMemberClassUser(access)
      await context.commit('mutationListMemberClassUser', response)
    },
    async actionEditMemberClassUser (context, access) {
      const response = await AxiosMember.axiosEditMemberClassUser(access)
      await context.commit('mutationEditMemberClassUser', response)
    },
    async actionPointUserForAdmin (context, access) {
      const response = await AxiosMember.axiosPointUserForAdmin(access)
      await context.commit('mutationPointUserForAdmin', response)
    },
    async actionSendPointUser (context, access) {
      const response = await AxiosMember.axiosSendPointUser(access)
      await context.commit('mutationSendPointUser', response)
    },
    async actionTransferPoint (context, access) {
      const response = await AxiosMember.axiosTransferPoint(access)
      await context.commit('mutationTransferPoint', response)
    },
    async actionDetailMemberUser (context, access) {
      const response = await AxiosMember.axiosDetailMemberUser(access)
      await context.commit('mutationDetailMemberUser', response)
    }
  }
}

export default ModuleMember
